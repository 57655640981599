import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { Field } from 'react-final-form';
import NumberInput from 'components/NumberInput';
import { formatMinutes } from 'utils/transform';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

const ObjectiveFields = ({ onSubmit, disabled }) => {
  const classes = useStyles();

  return (
    <form
      id="objective-form"
      onSubmit={onSubmit}
      noValidate
      className={classes.formContainer}
    >
      <Box className={classes.objectiveInput}>
        <Typography color="primary">Tempo</Typography>
        <Field
          name="horas"
          variant="outlined"
          placeholder="Hora"
          component={NumberInput}
          disabled={disabled}
        />
        <Typography variant="h5" color="primary">
          :
        </Typography>
        <Field
          name="minutos"
          variant="outlined"
          placeholder="Min"
          numberFormat={formatMinutes}
          component={NumberInput}
          disabled={disabled}
        />
      </Box>
      <Box className={classes.objectiveInput}>
        <Typography color="primary">Distância</Typography>
        <Field
          name="distancia_kilometros"
          variant="outlined"
          placeholder="Km"
          component={NumberInput}
          disabled={disabled}
        />
        <Typography variant="h5" color="primary">
          .
        </Typography>
        <Field
          name="distancia_metros"
          variant="outlined"
          placeholder="m"
          numberFormat="###"
          component={NumberInput}
          disabled={disabled}
        />
      </Box>
      <Box
        className={classes.objectiveInput}
        width="100%"
        justifyContent="start!important"
      >
        <Typography color="primary">Altimetria</Typography>
        <Field
          data-cy="altimetria"
          name="altimetria"
          variant="outlined"
          placeholder="m"
          component={NumberInput}
          disabled={disabled}
        />

        <Box />
      </Box>
      <Box className={classes.objectiveInput}>
        <Typography color="primary">Prazo</Typography>
        <Field
          data-cy="prazo"
          name="prazo"
          render={({ input: { value, onChange } }) => (
            <KeyboardDateTimePicker
              data-cy="data-largada"
              className={classes.prazoInput}
              value={value}
              KeyboardButtonProps={{
                style: { boxShadow: 'none' },
              }}
              ampm={false}
              autoOk
              onChange={date => onChange(date.toISOString())}
              inputVariant="outlined"
              disablePast
              disabled={disabled}
              invalidDateMessage=""
              format="dd/MM/yyyy HH:mm"
            />
          )}
        />
      </Box>
    </form>
  );
};

export default ObjectiveFields;
