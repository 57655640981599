import React from 'react';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { STATUS_APROVADO } from 'utils/constants';

const ApprovalStatusModal = ({ open, onClose, status }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{
        padding: 10,
      }}
    >
      <DialogContent>
        <Typography variant="h6" color="primary">
          Sucesso!
        </Typography>
        {status === STATUS_APROVADO ? (
          <>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ fontWeight: 'bold', fontSize: 16 }}
            >
              Solicitação aprovada com sucesso
            </Typography>
            <Typography variant="subtitle2">
              Esse usuário agora pode criar eventos próprios
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ fontWeight: 'bold', fontSize: 16 }}
            >
              Solicitação reprovada
            </Typography>
            <Typography variant="subtitle2">
              O usuário continuará sem a autorização para criar e editar eventos
              dentro do app
            </Typography>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ApprovalStatusModal;
