/* eslint-disable react/no-unescaped-entities */
import React, { useMemo, useRef, useEffect } from 'react';

import OiroLogo from 'images/logo.svg';
import { useAuth } from 'contexts/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Card, Typography, Button, Grid } from '@material-ui/core';
import LoginForm from 'forms/LoginForm';
import QRCode from 'react-qr-code';
import { Flag } from '@material-ui/icons';
import { FaPlusCircle } from 'react-icons/fa';
import { useStyles } from './styles';
import Footer from 'components/Footer';
import wsConnection from './websocket';
import { v4 as uuidv4 } from 'uuid';
import { Form, Field } from 'react-final-form';
import NumberInput from 'components/NumberInput';

function Login() {
  const history = useHistory();
  const location = useLocation();
  const { signIn, user } = useAuth();
  const { signInWebSocket } = useAuth();
  const { from } = location.state || { from: { pathname: '/' } };
  const classes = useStyles();
  const userIdLogin = useMemo(uuidv4, []);
  const loginCodeInputRef = useRef();

  const onSubmit = async values => {
    await signIn(values);
    history.replace(from);
  };

  const checkIfUserIsLogged = () => {
    if (user) {
      history.push('/');
    }
  };

  async function subscriptionChannel() {
    const ws = await wsConnection();

    const authQrcodeTopic = ws.subscribe(`auth/qrcode:${userIdLogin}`);

    authQrcodeTopic.on('message', evento => {
      signInWebSocket(evento);
      history.replace(from);
    });

    return ws;
  }

  const loginWithCode = async values => {
    if (!values.codigo_login) {
      return loginCodeInputRef.current.focus();
    }

    await signIn(values);
    history.replace(from);
  };

  useEffect(checkIfUserIsLogged, [user]);

  useEffect(() => {
    const ws = subscriptionChannel();

    return () => {
      if (!ws?.close) return;
      ws.close();
    };
  }, []);

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <img src={OiroLogo} className={classes.logo} />
      </Box>
      <Box className={classes.middleSection}>
        <Box className={classes.cardsWrapper}>
          <Card className={classes.loginCard}>
            <Box style={{ maxWidth: 300 }}>
              <Typography
                variant="h5"
                color="primary"
                style={{ marginBottom: 10 }}
              >
                Faça o login ou leia o QRcode ao lado
              </Typography>
              <LoginForm onSubmit={onSubmit} />
            </Box>
          </Card>
          <Card className={classes.codeCard}>
            <Box
              width={356}
              height={356}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={24}
              boxShadow="0 8px 24px #8158A415"
            >
              <QRCode value={userIdLogin} />
            </Box>
            <Typography style={{ marginTop: 20 }} variant="h6">
              ou
            </Typography>
            <Form
              onSubmit={loginWithCode}
              render={props => (
                <>
                  <Field
                    name="codigo_login"
                    inputRef={loginCodeInputRef}
                    className={classes.codeInput}
                    thousandSeparator=""
                    placeholder="Digite o código"
                    fullWidth
                    variant="outlined"
                    component={NumberInput}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={props.handleSubmit}
                  >
                    Criar evento
                  </Button>
                </>
              )}
            />
            <Grid style={{ marginTop: 70 }}>
              <Typography variant="h5" color="primary">
                Para logar com o Qr Code
              </Typography>
              <Typography
                className={classes.instructionsText}
                variant="subtitle1"
                color="primary"
              >
                Abra o aplicativo Oiro em seu celular
              </Typography>
              <Typography
                className={classes.instructionsText}
                variant="subtitle1"
                color="primary"
              >
                Entre na aba "Eventos" <Flag />
              </Typography>
              <Typography
                className={classes.instructionsText}
                variant="subtitle1"
                color="primary"
              >
                Clique em "+" <FaPlusCircle />
              </Typography>
              <Typography
                className={classes.instructionsText}
                variant="subtitle1"
                color="primary"
              >
                Escaneie o código QR acima
              </Typography>
            </Grid>
          </Card>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default Login;
