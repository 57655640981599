import React, { useMemo } from 'react';

import { Box, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import cameraIcon from 'images/camera-icon.png';
import { When } from 'react-if';

import { useStyles as useTrajetoCardStyles } from 'components/TrajetoCard/styles';
import { useStyles } from './styles';

const ImagePicker = ({
  value,
  onChange,
  height = 190,
  width = '100%',
  disabled = false,
}) => {
  const trajetoCardClasses = useTrajetoCardStyles();
  const classes = useStyles();
  const image = useMemo(
    () => (value?.size ? URL.createObjectURL(value) : undefined),
    [value],
  );

  const onChangeImage = image => {
    if (image === null) {
      return onChange();
    }

    if (!image) return;

    onChange(image);
  };

  return (
    <>
      <Box
        style={{
          backgroundImage: value && `url(${value.size ? image : value.url})`,
          backgroundSize: value && 'cover',
          backgroundColor: value && 'transparent',
        }}
        height={height}
        width={width}
        className={classes.eventImageDisplay}
      >
        <When condition={!disabled}>
          <img
            onClick={() => document.querySelector('input[type=file]').click()}
            src={cameraIcon}
          />
        </When>
        <When condition={!!value}>
          <When condition={!disabled}>
            <IconButton
              onClick={() => onChangeImage(null)}
              className={trajetoCardClasses.deleteRouteButton}
            >
              <Delete />
            </IconButton>
          </When>
        </When>
      </Box>
      <input
        multiple={false}
        accept="image/*"
        type="file"
        onChange={event => onChangeImage(event.target.files[0])}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default React.memo(ImagePicker);
