import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  footer: {
    backgroundColor: '#563777',
    height: 174,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerLinks: {
    color: '#E2D052',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    textDecoration: 'none',
    marginLeft: 30,
    '&:hover': {
      filter: 'brightness(0.9)',
    },
  },
  footerSocialIcons: {
    color: 'white',
  },
});
