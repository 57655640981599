import React, { useState } from 'react';
import { Box, Button, Card, Typography } from '@material-ui/core';
import { TextField } from 'final-form-material-ui';
import { useStyles } from './styles';
import { Add, ArrowBack, ArrowForward, Flag } from '@material-ui/icons';

import TrajetoCard from 'components/TrajetoCard';
import { Grid } from '@material-ui/core';
import ImagePicker from 'components/ImagePicker';
import EventStructure from '../EventStructure';
import { Field, Form } from 'react-final-form';
import Autocomplete from 'components/Autocomplete';
import { toast } from 'react-toastify';
// import { schema } from './schema';
import {
  CICLISMO,
  EVENTO_MODALIDADE,
  EVENTO_PRESENCIAL,
  EVENTO_VISIBILIDADE,
  TIPOS_BIKE,
  TIPOS_EVENTO_CICLISMO,
  TIPOS_EVENTO_CORRIDA,
  TIPO_ATIVIDADE,
} from 'utils/constants';
import Radio from 'components/Radio';
import ObjectiveFields from 'components/ObjectiveFields';
// import { validate } from 'utils/validate';
import { When, Then, If, Else } from 'react-if';
import ConfirmDialog from 'dialogs/ConfirmDialog';
import TrajetoDialog from 'dialogs/TrajetoDialog';

const EventOverall = ({
  initialValues,
  goBack,
  onDeleteTrajeto,
  onSubmitEvent,
  onConcludeEvent,
  onCreateTrajeto,
  isEditing = false,
  onlyRead = false,
}) => {
  const classes = useStyles();
  const [directionsResponses, setDirectionsResponses] = useState([]);
  const [openConfirmDeleteTrajeto, setOpenConfirmDeleteTrajeto] = useState(
    false,
  );
  const [deletingTrajeto, setDeletingTrajeto] = useState(null);
  const [openCreateTrajeto, setOpenCreateTrajeto] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState();

  return (
    <Form
      onSubmit={values => {
        onSubmitEvent(values);
      }}
      initialValues={{
        imagemEvento: initialValues.photoAndName?.imagemEvento,
        tipo_atividade: String(initialValues.exerciseType),
        modality: String(initialValues.modality),
        visibility: String(initialValues.privacy),
        name: initialValues.photoAndName?.nome,
        description: initialValues.descriptionAndRules?.description,
        rules: initialValues.descriptionAndRules?.rules,
        tipo_bike: String(initialValues.bikeType),
        tipo_evento: initialValues.type,
        structure: initialValues.structure,
        horas: initialValues.objective?.horas,
        minutos: initialValues.objective?.minutos,
        distancia_kilometros: initialValues.objective?.distancia_kilometros,
        distancia_metros: initialValues.objective?.distancia_metros,
        altimetria: Number(initialValues.objective?.altimetria),
        prazo: initialValues.objective?.prazo,
        routes: initialValues.routes,
      }}
      render={({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Card className={classes.eventDetailsCard}>
            <Grid container direction="row">
              <Grid item container direction="row" xs={12}>
                <Grid item xs={6}>
                  <Field
                    name="imagemEvento"
                    render={({ input: { value, onChange } }) => {
                      return (
                        <ImagePicker
                          value={value}
                          height={'100%'}
                          disabled={onlyRead}
                          width={360}
                          onChange={imageUrl => onChange(imageUrl)}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item container direction="column" xs={6}>
                  <Radio
                    name="tipo_atividade"
                    label="Tipo de atividade"
                    disabled
                    options={TIPO_ATIVIDADE}
                  />
                  <Radio
                    name="modality"
                    label="Modalidade"
                    disabled
                    options={EVENTO_MODALIDADE}
                  />
                  <Radio
                    name="visibility"
                    label="Visibilidade"
                    disabled={onlyRead}
                    options={EVENTO_VISIBILIDADE}
                  />
                  <Box className={classes.eventName}>
                    <Typography color="primary">Nome do evento</Typography>
                    <Field
                      name="name"
                      variant="outlined"
                      disabled={onlyRead}
                      fullWidth
                      component={TextField}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item container justify="space-between" xs={12}>
                <Grid item xs={6}>
                  <Box className={classes.eventDescription} mr={2}>
                    <Typography color="primary">Descrição</Typography>
                    <Field
                      name="description"
                      multiline
                      rows={6}
                      fullWidth
                      disabled={onlyRead}
                      variant="outlined"
                      component={TextField}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.eventDescription}>
                    <Typography color="primary">Regras</Typography>
                    <Field
                      name="rules"
                      multiline
                      fullWidth
                      disabled={onlyRead}
                      rows={6}
                      variant="outlined"
                      component={TextField}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Grid
            container
            className={classes.bottomDetailsCards}
            justify="center"
          >
            <When
              condition={
                values.tipo_atividade === String(CICLISMO) ||
                values.modality === String(EVENTO_PRESENCIAL)
              }
            >
              <Grid item xs={6}>
                <Card className={classes.bikeTypeAndEventTypeCard}>
                  <When condition={values.tipo_atividade === String(CICLISMO)}>
                    <Box>
                      <Typography color="primary">Tipos de bike</Typography>
                      <Field
                        name="tipo_bike"
                        options={TIPOS_BIKE}
                        disabled={onlyRead}
                        kind="value"
                        component={Autocomplete}
                      />
                    </Box>
                  </When>
                  <When
                    condition={values.modality === String(EVENTO_PRESENCIAL)}
                  >
                    <Box>
                      <Typography color="primary">Tipo de evento</Typography>
                      <Field
                        name="tipo_evento"
                        options={
                          values.tipo_atividade === String(CICLISMO)
                            ? TIPOS_EVENTO_CICLISMO
                            : TIPOS_EVENTO_CORRIDA
                        }
                        disabled={onlyRead}
                        kind="value"
                        component={Autocomplete}
                      />
                    </Box>
                  </When>
                </Card>
              </Grid>
            </When>
            <Grid item xs={6}>
              <Card className={classes.structureCard}>
                <If condition={values.modality === String(EVENTO_PRESENCIAL)}>
                  <Then>
                    <Field
                      name="structure"
                      render={({ input: { onChange, value } }) => (
                        <EventStructure
                          small
                          disabled={onlyRead}
                          values={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Then>
                  <Else>
                    <Typography variant="h6" color="primary">
                      Objetivo
                    </Typography>
                    <ObjectiveFields disabled={onlyRead} />
                  </Else>
                </If>
              </Card>
            </Grid>
          </Grid>
          <Box className={classes.routesList}>
            {values.routes?.map((route, index) => (
              <TrajetoCard
                key={route.id}
                route={{ ...route, index: index + 1 }}
                index={index}
                disabled={onlyRead}
                editTrajeto={() => {
                  setSelectedRoute(route);
                }}
                deleteTrajeto={() => {
                  if (initialValues.routes.length === 1) {
                    return toast.error(
                      'Você não pode remover todos os seus trajetos, para removê-lo, volte e crie um novo trajeto',
                      {
                        autoClose: 7000,
                      },
                    );
                  }
                  setOpenConfirmDeleteTrajeto(true);
                  setDeletingTrajeto(route);
                }}
              />
            ))}
            <Card className={classes.footerCard}>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <If condition={isEditing}>
                    <Then>
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<Add />}
                        disabled
                        onClick={onCreateTrajeto}
                      >
                        Criar trajeto
                      </Button>
                    </Then>
                    <Else>
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<ArrowBack />}
                        onClick={goBack}
                      >
                        Voltar
                      </Button>
                    </Else>
                  </If>
                </Grid>
                <Grid item>
                  <If condition={isEditing}>
                    <Then>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={onConcludeEvent}
                      >
                        Concluir evento
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: 20 }}
                        endIcon={<ArrowForward />}
                        onClick={handleSubmit}
                      >
                        Salvar
                      </Button>
                    </Then>
                    <Else>
                      <If condition={!onlyRead}>
                        <Then>
                          <Button
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: 20 }}
                            endIcon={<ArrowForward />}
                            onClick={handleSubmit}
                          >
                            Criar evento
                          </Button>
                        </Then>
                        <Else>
                          <Button
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: 20 }}
                            startIcon={<Flag />}
                            onClick={() =>
                              window.open(
                                process.env.REACT_APP_PLAY_STORE_LINK,
                                '_blank',
                              )
                            }
                          >
                            Participar
                          </Button>
                        </Else>
                      </If>
                    </Else>
                  </If>
                </Grid>
              </Grid>
            </Card>
          </Box>

          <ConfirmDialog
            open={openConfirmDeleteTrajeto}
            title="Deletar trajeto?"
            onClose={() => setOpenConfirmDeleteTrajeto(false)}
            onAction={() => {
              onDeleteTrajeto(deletingTrajeto.trajeto_id);
              setDirectionsResponses(
                directionsResponses.filter(
                  direction => direction.id !== deletingTrajeto.id,
                ),
              );
            }}
          />

          <Field
            name="routes"
            render={({ input: { value, onChange } }) => (
              <TrajetoDialog
                open={openCreateTrajeto || !!selectedRoute}
                currentRoute={selectedRoute}
                onClose={() => {
                  if (openCreateTrajeto) {
                    setOpenCreateTrajeto(false);
                  }
                  setSelectedRoute();
                }}
                index={value.findIndex(
                  item =>
                    (item.trajeto_id || item.id) ===
                    (selectedRoute?.trajeto_id || selectedRoute?.id),
                )}
                onEdit={routeToEdit => {
                  const currentRoutes = value;
                  const itemIndexToRemove = currentRoutes.findIndex(
                    item =>
                      (item.trajeto_id || item.id) ===
                      (selectedRoute.trajeto_id || selectedRoute.id),
                  );
                  console.log({
                    routeToEdit,
                    currentRoutes,
                    itemIndexToRemove,
                  });

                  currentRoutes.splice(itemIndexToRemove, 1, {
                    ...selectedRoute,
                    ...routeToEdit,
                    hasChanged: true,
                  });

                  onChange(currentRoutes);
                }}
              />
            )}
          />
        </form>
      )}
    />
  );
};

export default React.memo(EventOverall);
