import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  TextField,
  Box,
  Card,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Delete, PanTool } from '@material-ui/icons';

import {
  GoogleMap,
  BicyclingLayer,
  Marker,
  Autocomplete,
} from '@react-google-maps/api';

import { toast } from 'react-toastify';
import { AiOutlineArrowRight } from 'react-icons/ai';

import CustomDirections from 'components/CustomDirections';
import ConfirmDialog from 'dialogs/ConfirmDialog/ConfirmDialog';

import {
  calculateDistance,
  convertDirectionsToTrajeto,
  getDirecitons,
  getFormattedNumber,
} from 'utils/transform';
import { useStyles } from './styles';

const travelMode = 'WALKING';

const EventRouteMap = ({
  onChange,
  currentRoute,
  currentRouteId,
  editing = false,
  index,
}) => {
  const classes = useStyles();
  const mapRef = useRef(null);
  const originAutocompleteRef = useRef(null);
  const originAutocompleteInputRef = useRef(null);
  const destinationAutocompleteRef = useRef(null);
  const destinationAutocompleteInputRef = useRef(null);
  const [directionsResponse, setDirectionsResponse] = useState();
  const [distance, setDistance] = useState(0);
  const [center, setCenter] = useState({
    lat: -25.466721830819118,
    lng: -49.275370455793066,
  });

  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();
  const [showConfirmRouteWipe, setShowConfirmRouteWipe] = useState();
  const [coordsToAdd, setCoordsToAdd] = useState();

  const [loadingRoute, setLoadingRoute] = useState(false);

  const onMapLoad = useCallback(map => {
    mapRef.current = map;
  }, []);

  const loadRoute = async ({ origin, destination, waypoints }) => {
    if (!origin || !destination || !waypoints) return;

    setLoadingRoute(true);
    try {
      const waypointsFormatted =
        waypoints &&
        waypoints.map(point => ({
          location: new google.maps.LatLng(point.lat, point.lng),
        }));

      const { result, status } = await getDirecitons({
        origin,
        destination,
        waypoints: waypointsFormatted,
        editing,
        travelMode,
      });

      if (status !== google.maps.DirectionsStatus.OK) throw new Error();

      setDirectionsResponse(result);
      setDistance(() => calculateDistance(result.routes[0].legs));

      // if (currentRoute) {
      //   return;
      // }

      console.log({ currentRoute, currentRouteId, editing });

      const route = convertDirectionsToTrajeto({
        googleDirections: result,
        currentRouteId: currentRouteId || currentRoute.id,
        routeRest: { ...currentRoute, hasChanged: editing },
      });

      onChange(route);
    } catch (err) {
      toast.error(
        'Houve um erro ao conectar com o Google Maps, tente novamente mais tarde',
      );
    } finally {
      setLoadingRoute(false);
    }
  };

  const onMapClick = async coords => {
    const geocoder = new google.maps.Geocoder();

    if (!origin) {
      const location = new google.maps.LatLng(coords.lat, coords.lng);

      geocoder.geocode({ location }, results => {
        originAutocompleteInputRef.current.value =
          results?.[0]?.formatted_address;
      });

      return setOrigin(coords);
    }

    if (!destination) {
      const geocoder = new google.maps.Geocoder();
      const location = new google.maps.LatLng(coords.lat, coords.lng);

      geocoder.geocode({ location }, results => {
        destinationAutocompleteInputRef.current.value =
          results?.[0]?.formatted_address;
      });

      setDestination(coords);
      return loadRoute({ origin, destination: coords, waypoints: [] });
    }

    setCoordsToAdd(coords);
    setShowConfirmRouteWipe(true);
  };

  const onOriginAutocompleteLoad = useCallback(autocomplete => {
    originAutocompleteRef.current = autocomplete;
  }, []);

  const onDestinationAutocompleteLoad = useCallback(autocomplete => {
    destinationAutocompleteRef.current = autocomplete;
  }, []);

  const onPlaceChanged = point => {
    const autocompleteRef =
      point === 'ORIGIN' ? originAutocompleteRef : destinationAutocompleteRef;
    if (autocompleteRef.current === null) return;

    const sessionToken = new google.maps.places.AutocompleteSessionToken();
    const places = new google.maps.places.PlacesService(mapRef.current);
    const autocompleteService = new google.maps.places.AutocompleteService();

    const autocompleteInputRef =
      point === 'ORIGIN'
        ? originAutocompleteInputRef
        : destinationAutocompleteInputRef;
    const query = autocompleteInputRef.current.value;

    if (!query) {
      return toast.error('Insira um endereço para adicionar ao mapa');
    }

    autocompleteService.getQueryPredictions(
      {
        input: autocompleteInputRef.current.value,
        sessionToken,
      },
      ([place]) => {
        places.getDetails({ placeId: place.place_id }, details => {
          addPointByAddress(details, point);
        });
      },
    );
  };

  const addOriginPoint = coords => {
    if (!origin && !!destination) {
      setOrigin(coords);

      return loadRoute({ origin: coords, destination, waypoints: [] });
    }

    if (!origin) {
      return setOrigin(coords);
    }

    setOrigin(coords);
    if (!destination) return;
    loadRoute({ origin: coords, destination, waypoints: [] });
  };

  const addDestinationPoint = coords => {
    if (!destination && !!origin) {
      setDestination(coords);

      return loadRoute({ origin, destination: coords, waypoints: [] });
    }

    if (!destination) {
      return setDestination(coords);
    }

    setDestination(coords);
    loadRoute({ origin, destination: coords, waypoints: [] });
  };

  const addPointByAddress = (place, point) => {
    if (!place.geometry) {
      return toast.error('Não foi possível localizar o endereço inserido.');
    }

    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const coords = { lat, lng };

    mapRef.current.panToBounds(new google.maps.LatLngBounds(coords), 200);

    if (point === 'ORIGIN') {
      addOriginPoint(coords);
      return;
    }

    addDestinationPoint(coords);
  };

  const clearRoute = () => {
    setDirectionsResponse();
    setDistance(0);
    setDestination();
    onChange({});
    setOrigin();
    originAutocompleteInputRef.current.value = '';
    destinationAutocompleteInputRef.current.value = '';
  };

  const handleRouteDrag = directions => {
    if (!destination) return;
    console.log(directions);

    const distance = calculateDistance(directions.routes[0].legs);
    setDistance(distance);

    const updatedRoute = convertDirectionsToTrajeto({
      googleDirections: directions,
      currentRouteId: currentRouteId || currentRoute.id,
      routeRest: { ...currentRoute, hasChanged: editing },
    });

    const directionOrigin = directions.request.origin;
    const directionDestination = directions.request.destination;

    const geocoder = new google.maps.Geocoder();
    const originLocation = {
      lat: directionOrigin?.location?.lat() || directionOrigin?.lat(),
      lng: directionOrigin?.location?.lng() || directionOrigin?.lng(),
    };
    const destinationLocation = {
      lat: directionDestination?.location?.lat() || directionDestination?.lat(),
      lng: directionDestination?.location?.lng() || directionDestination?.lng(),
    };

    geocoder.geocode({ location: originLocation }, results => {
      originAutocompleteInputRef.current.value =
        results?.[0]?.formatted_address;
    });

    geocoder.geocode({ location: destinationLocation }, results => {
      destinationAutocompleteInputRef.current.value =
        results?.[0]?.formatted_address;
    });

    onChange(updatedRoute);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  console.log(
    originAutocompleteRef.current,
    destinationAutocompleteRef.current,
  );

  return (
    <Box className={classes.container}>
      <Typography variant="h6" color="primary">
        Trajeto {!editing ? currentRouteId : index + 1}
      </Typography>
      <Box className={classes.formContainer}>
        <Box className={classes.inputContainer}>
          <Box className={classes.inputField}>
            <Typography color="primary">Ponto de partida</Typography>
            <Box flexDirection="row" display="flex">
              <>
                <Autocomplete
                  data-cy="adiciona-ponto"
                  onLoad={onOriginAutocompleteLoad}
                  onPlaceChanged={() => {
                    const place = originAutocompleteRef.current.getPlace();

                    addPointByAddress(place, 'ORIGIN');
                  }}
                >
                  <TextField
                    inputRef={originAutocompleteInputRef}
                    variant="outlined"
                    placeholder="Digite um endereço..."
                  />
                </Autocomplete>
              </>
              <IconButton
                onClick={() => onPlaceChanged('ORIGIN')}
                data-cy="button-plus"
                size="small"
                color="primary"
              >
                <AiOutlineArrowRight color="white" size={25} />
              </IconButton>
            </Box>
          </Box>
          <Box className={classes.inputField}>
            <Typography color="primary">Ponto de chegada</Typography>
            <Box flexDirection="row" display="flex">
              <>
                <Autocomplete
                  data-cy="adiciona-ponto"
                  onLoad={onDestinationAutocompleteLoad}
                  onPlaceChanged={() => {
                    const place = destinationAutocompleteRef.current.getPlace();

                    addPointByAddress(place, 'DESTINATION');
                  }}
                >
                  <TextField
                    inputRef={destinationAutocompleteInputRef}
                    variant="outlined"
                    placeholder="Digite um endereço..."
                    disabled={!origin}
                  />
                </Autocomplete>
              </>
              <IconButton
                onClick={() => onPlaceChanged('DESTINATION')}
                data-cy="button-plus"
                size="small"
                color="primary"
                disabled={!origin}
              >
                <AiOutlineArrowRight color="white" size={25} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Card className={classes.mapContainer}>
          <GoogleMap
            id="map"
            mapContainerStyle={{ width: '100%', height: '100%' }}
            zoom={16}
            center={center}
            onClick={mapEvent => {
              if (loadingRoute) return;
              onMapClick({
                lat: mapEvent.latLng.lat(),
                lng: mapEvent.latLng.lng(),
              });
            }}
            onLoad={onMapLoad}
            clickableIcons={false}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
          >
            {!directionsResponse && <Marker position={origin} />}

            <CustomDirections
              handleRouteDrag={handleRouteDrag}
              directionsResponse={directionsResponse}
              options={{
                preserveViewport: false,
              }}
            />

            <BicyclingLayer />

            <IconButton
              style={{ display: !directionsResponse && 'none' }}
              onClick={clearRoute}
              className={classes.clearRouteButton}
              disabled={loadingRoute}
            >
              <Delete />
            </IconButton>
          </GoogleMap>
        </Card>
        <Box className={classes.routeDetails}>
          <Typography color="primary">
            Distância <strong>{getFormattedNumber(distance / 1000)}km</strong>
          </Typography>
          {!!origin && !!destination ? (
            <Typography color="textSecondary" className={classes.helperText}>
              <PanTool />
              Segure e arraste o traçado da rota para customizá-la
            </Typography>
          ) : null}
        </Box>
      </Box>

      <ConfirmDialog
        open={showConfirmRouteWipe}
        title="Resetar rota?"
        content="Ao seguir, a rota será resetada."
        onAction={() => {
          clearRoute();
          setOrigin(coordsToAdd);
          const location = new google.maps.LatLng(
            coordsToAdd.lat,
            coordsToAdd.lng,
          );
          const geocoder = new google.maps.Geocoder();

          geocoder.geocode({ location }, results => {
            originAutocompleteInputRef.current.value =
              results?.[0]?.formatted_address;
          });
        }}
        onClose={() => setShowConfirmRouteWipe(false)}
      />
    </Box>
  );
};

export default EventRouteMap;
