import React from 'react';
import { useStyles } from './styles';
import { Box, Typography } from '@material-ui/core';
import OiroLogo from 'images/logo.svg';
import Footer from 'components/Footer';

const PrivacyAndPolicy = () => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.header}>
        <Box className={classes.headerLayout}>
          <a href="/">
            <img src={OiroLogo} width={90} />
          </a>
          <Typography variant="h5">Termos de Serviço</Typography>
        </Box>
      </Box>
      <Box className={classes.layout}>
        <Typography>Data de vigência: 10 de julho de 2023.</Typography>
        <Typography>
          Bem-vindo(a) à Oiro. Gostaríamos que você conhecesse os seus direitos
          e os nossos direitos com relação ao fornecimento dos Serviços. Leia-os
          atentamente, aqui estão alguns destaques:
        </Typography>
        <Typography>
          Sua privacidade é de importância crucial para nós. Veja como coletamos
          e usamos suas informações pessoais em nossa Política de privacidade.
        </Typography>
        <Typography>
          Você pode cancelar sua assinatura ou excluir sua conta a qualquer
          momento.
        </Typography>
        <Typography>
          Você é proprietário(a) do seu conteúdo, mas nos concede o direito de
          usá-lo.
        </Typography>
        <Typography>
          Esperamos que você seja respeitoso, e podemos cancelar sua conta se
          você agir de forma inadequada.
        </Typography>
        <Typography>
          A Oiro não é responsável por suas atividades e nenhuma garantia é
          feita pela Oiro.
        </Typography>
        <Typography>
          Podemos cancelar sua conta se você agir de forma inapropriada.
        </Typography>
        <Typography>
          Queremos seus comentários, e você nos autoriza a usá-los.
        </Typography>
        <Typography>
          Você concorda em participar em arbitragem em caso de disputa entre
          nós.
        </Typography>
        <Typography>
          Você pode entrar em contato conosco facilmente se tiver perguntas ou
          precisar de ajuda.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Termos de serviço
          </Typography>
        </Box>
        <Typography>
          Os websites e respectivos serviços e aplicativos móveis do Oiro
          (coletivamente, os “Serviços”) são disponibilizados para você pelO
          Oiro e seu responsável NORMAN BERNANDO FRIESEN, sujeitos a estes
          Termos de serviço (os “Termos”) e de acordo com a Política de
          privacidade do Oiro (a “Política de privacidade”). Mais informações
          sobre os Serviços podem ser encontradas em{' '}
          <a
            href="https://portal.oiro.app/."
            target="_blank"
            rel="noreferrer"
            style={{ color: 'blue', pDecorationLine: 'underline' }}
          >
            https://portal.oiro.app/.
          </a>
        </Typography>
        <Typography>
          AO ACESSAR, UTILIZAR OU FAZER UPLOAD OU DOWNLOAD DE QUAISQUER
          INFORMAÇÕES OU MATERIAIS DE OU PARA OS SERVIÇOS, OU AO INDICAR QUE
          ACEITA ESTES TERMOS AO CRIAR UMA CONTA, CLICAR NO LINK DE INSCRIÇÃO OU
          QUALQUER OUTRO MECANISMO SEMELHANTE, VOCÊ ESTÁ CONCORDANDO COM ESTES
          TERMOS. CASO NÃO CONCORDE COM ESTES TERMOS, NÃO ACESSE OU UTILIZE OS
          SERVIÇOS.
        </Typography>
        <Typography>
          Se acessar ou usar os Serviços em nome de uma empresa ou outra
          entidade, você declara ter a autoridade para obrigar tal entidade e
          suas afiliadas a cumprir estes Termos e que ela está totalmente
          obrigada a cumpri-los. Nesse caso, o termo “você” estará se referindo
          a tal entidade e suas afiliadas. Se não tiver tal autoridade, você não
          poderá acessar ou usar os Serviços. Estes Termos contêm isenções de
          garantias e limitações de responsabilidade que podem se aplicar a
          você.
        </Typography>
        <Typography>
          Os Serviços do Oiro não podem ser fornecidos e o acordo descrito nos
          Termos de serviço não pode ser cumprido sem O Oiro processar dados
          sobre você, e outros atletas do Oiro, incluindo seus dados de
          localização. O processamento dos dados que você compartilha com O
          Oiro, inclusive dados de localização, é essencial para os Serviços que
          fornecemos e uma parte necessária do nosso cumprimento do acordo que
          temos com você.
        </Typography>
        <Typography>
          Aviso sobre resolução de disputas: estes Termos contêm disposições que
          determinam como as disputas entre você e O Oiro serão resolvidas,
          inclusive um acordo de arbitragem, que exigirá, com exceção limitada,
          que você apresente as reivindicações que tiver contra nós à arbitragem
          final e vinculativa e limitará suas reivindicações contra O Oiro
          individualmente, a menos que você renuncie de acordo com as instruções
          abaixo.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Registro e contas
          </Typography>
          <Typography>
            Os Serviços são destinados unicamente a pessoas maiores de 16 anos,
            para usar os Serviços. Se você não tiver atingido a idade legal para
            celebrar um contrato vinculativo em sua jurisdição, você declara que
            seus pais ou seu responsável legal leram e concordaram com estes
            Termos em seu nome.
          </Typography>
        </Box>
        <Typography>
          Para usar os Serviços, você deve se cadastrar. Você concorda em: (a)
          fornecer informações verdadeiras, precisas, atuais e completas sobre
          você (“Dados do Atleta”) e (b) manter e prontamente atualizar os Dados
          do Atleta. Você concorda que O Oiro pode usar seus Dados do Atleta
          para fornecer os Serviços que você acessa ou usa e de outras formas
          definidas nestes Termos. Se você fornecer quaisquer Dados do Atleta
          que sejam imprecisos ou não sejam atuais, ou se O Oiro tiver motivos
          razoáveis para suspeitar que tais Dados do Atleta sejam imprecisos ou
          não atuais, O Oiro terá o direito de suspender ou encerrar a sua conta
          e recusar a utilização atual ou futura dos Serviços. Em relação à sua
          utilização dos Serviços, você declara não ser uma pessoa impedida de
          receber serviços dentro das leis de qualquer jurisdição aplicável.
        </Typography>
        <Typography>
          Você é o único responsável por manter a confidencialidade da senha
          associada à sua conta e restringir o acesso à sua senha, seu
          computador e dispositivo móvel enquanto estiver conectado(a) aos
          Serviços. Você aceita a responsabilidade por todas as atividades
          ocorridas em sua conta ou de seu computador ou dispositivo móvel. Nós
          nos empenhamos em empregar medidas razoáveis de segurança para
          prevenir o acesso não autorizado à sua conta. Não podemos, no entanto,
          garantir a segurança absoluta da sua conta, do seu Conteúdo ou das
          informações pessoais que você fornece, e não podemos prometer que
          nossas medidas de segurança irão evitar que “hackers” de terceiros
          acessem ilegalmente os Serviços e seus conteúdos. Você concorda em
          notificar imediatamente O Oiro de qualquer uso não autorizado da sua
          conta ou senha, ou qualquer outra infração de segurança, e confirma
          que entende todos os riscos de acesso não autorizado aos Dados do
          Atleta e quaisquer outras informações ou conteúdos que você fornecer à
          Oiro.
        </Typography>
        <Typography>
          Você pode se cadastrar ou fazer login em sua conta por meio de uma
          rede de terceiros, como o Facebook ou Google. Nesse caso, você
          autoriza O Oiro a preencher os campos de inscrição e outros campos
          relevantes da sua conta e/ou usar tais credenciais de terceiros para
          fazer login na sua conta. Se você conectar sua conta a uma rede de
          terceiros, concorda em seguir os termos e condições e as políticas
          aplicáveis aos terceiros.
        </Typography>
        <Typography>
          Em virtude da conexão de alguns dos Serviços ao API do Google Maps,
          você concorda em cumprir os Termos de Serviço adicionais do Google
          Maps/Google Earth (inclusive a Política de privacidade do Google) com
          relação ao seu uso de tais Serviços.
        </Typography>
        <Typography style={{ fontWeight: 'bold' }}>
          PAGAMENTOS E TAXAS
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>Pagamentos</Typography>
          <Typography>
            Para pagar qualquer taxa, você deve designar e fornecer informações
            sobre sua forma de pagamento preferencial (por exemplo, cartão de
            crédito, serviço de pagamento online, terceiros, como o iTunes ou
            Google Play, ou qualquer outra forma de pagamento disponibilizada
            pelO Oiro) (a “Forma de pagamento”). Se fornecer informações para
            pagamento, você autoriza O Oiro e determinados provedores de
            serviços terceirizados, redes de cartão de pagamento e empresas de
            processamento de pagamento a receber, armazenar e criptografar suas
            informações de pagamento. É possível alterar a Forma de pagamento ou
            atualizar suas informações visitando{' '}
            <a
              href="https://portal.oiro.app/formasdepagamento"
              target="_blank"
              rel="noreferrer"
            >
              https://portal.oiro.app/formasdepagamento
            </a>
            . Se tiver pago por meio da loja do Google Play ou iTunes, você pode
            alterar sua Forma de pagamento pela conta da respectiva loja.
            Reembolsos ou créditos não serão oferecidos pelO Oiro, salvo
            conforme disposto nestes Termos. Se o seu provedor de pagamento
            determinar que há cobranças fraudulentas em sua conta resultantes do
            uso da sua Forma de pagamento dos Serviços, entre em contato conosco
            imediatamente em{' '}
            <a
              href="https://portal.oiro.app/sac"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'blue', pDecorationLine: 'underline' }}
            >
              https://portal.oiro.app/sac
            </a>
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>Taxas</Typography>
          <Typography>
            Para acessar certas funcionalidades dos Serviços, pode ser
            necessário pagar as taxas de assinatura. As taxas de assinatura,
            juntamente com quaisquer impostos devidos, poderão ser pagas mensal
            ou anualmente. O pagamento de todas as taxas de assinatura deve ser
            antecipado. Para os atletas que alterarem suas assinaturas de mensal
            para anual, as taxas anuais passarão a vigorar no início da próxima
            data de cobrança. Você concorda em pagar as taxas de assinatura e
            outras despesas devidas relativas à sua conta do Oiro, seja de forma
            avulsa ou por assinatura. O Oiro reserva-se o direito de aumentar as
            taxas de assinatura e os respectivos impostos ou instituir novas
            taxas a qualquer momento, mediante notificação prévia razoável.
          </Typography>
        </Box>

        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Renovação automática
          </Typography>
          <Typography>
            As taxas de assinatura serão faturadas automaticamente no início do
            período mensal ou anual, conforme aplicável. Essas taxas serão
            renovadas automaticamente até que a assinatura seja alterada para
            uma opção mais simples ou encerrada. Sua taxa de assinatura será
            igual à cobrança inicial, a menos que você seja notificado(a) de
            outra forma com antecedência. Você pode cancelar sua assinatura a
            qualquer momento, conforme descrito a seguir.
          </Typography>
        </Box>

        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Período de carência
          </Typography>
          <Typography>
            Você poderá ter o direito de desistir do serviço e receber o
            reembolso integral dentro de 7 (sete) dias (o “Período de
            carência”), contanto que você não tenha se conectado ou de outra
            forma resgatado ou começado a utilizar os Serviços como assinante
            durante o período de carência.
          </Typography>
        </Box>

        <Typography style={{ fontWeight: 'bold' }}>CANCELAMENTO</Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Cancelamento de assinatura
          </Typography>
          <Typography>
            Se você se inscreveu no Oiro.com.br, pode cancelar sua assinatura
            visitando a página “Conta” e selecionando “Excluir conta”. Se tiver
            se cadastrado pela loja do Google Play ou do iTunes, você poderá
            cancelar sua assinatura fazendo a alteração na respectiva loja. O
            cancelamento da assinatura terá efeito no final de seu ciclo de
            fatura atual. Quando sua assinatura terminar, sua conta continuará a
            existir e passará a ser uma assinatura gratuita. Você pode renovar
            sua assinatura a qualquer momento sem abrir uma nova conta,
            observando que as taxas de assinatura podem ter aumentado. Você pode
            excluir sua conta a qualquer momento.
          </Typography>
        </Box>

        <Typography style={{ fontWeight: 'bold' }}>
          CONTEÚDO E CONDUTA
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>Conteúdo</Typography>
          <Typography>
            Você é o(a) proprietário(a) das informações, dados, po, sons,
            fotografias, gráficos, vídeo, mensagens, publicações, marcações ou
            outros materiais que você disponibilizar relacionados aos Serviços
            (“Conteúdo”), sejam eles postados publicamente, transmitidos de
            forma privada ou enviados por meio de uma API de terceiros (por
            exemplo, uma fotografia enviada pelo Instagram). Rotas e segmentos
            públicos criados usando os Serviços não são considerados conteúdo.
            Você nos concede uma licença não exclusiva, transferível,
            sublicenciável, livre de royalties e mundial para usar, reproduzir,
            modificar, adaptar, publicar, traduzir, criar trabalhos derivados,
            distribuir, apresentar publicamente e exibir qualquer Conteúdo e
            qualquer nome, nome de usuário ou imagem que você publicar nos
            Serviços ou em conexão com eles em todos os formatos de mídia e
            canais conhecidos atualmente ou desenvolvidos posteriormente sem
            compensação para você. A licença termina quando você excluir seu
            Conteúdo ou conta.
          </Typography>
        </Box>

        <Typography>
          Você compreende que você, e não O Oiro, é totalmente responsável por
          todo o Conteúdo carregado, publicado, enviado por e-mail, transmitido
          ou disponibilizado de outra forma por meio dos Serviços. O Oiro não
          controla, examina ou monitora regularmente o Conteúdo publicado nos
          Serviços por outras pessoas e, por isso, não garante a precisão,
          integridade ou qualidade desse Conteúdo. O Oiro pode, a seu exclusivo
          critério, examinar, monitorar, ocultar, recusar ou remover qualquer
          Conteúdo, ou remover qualquer Conteúdo que viole os Termos ou que seja
          questionável. Você compreende que, ao utilizar os Serviços, poderá ser
          exposto(a) a Conteúdo ofensivo, indecente ou questionável. Em
          circunstância alguma, O Oiro será responsável por qualquer Conteúdo,
          inclusive, sem limitação, quaisquer erros ou omissões em Conteúdo, ou
          perdas ou danos de qualquer espécie incorridos como resultado do uso
          do Conteúdo disponibilizado nos Serviços. Você concorda em assumir
          todos os riscos associados ao uso do Conteúdo disponível com relação
          aos Serviços, inclusive os de confiar na precisão, integridade ou
          utilidade do Conteúdo.
        </Typography>
        <Typography>
          Você concorda que O Oiro não é responsável pelos Conteúdos publicados
          nos Serviços nem os endossa. Se o seu Conteúdo violar estes Termos,
          você poderá arcar com responsabilidade legal por esse Conteúdo. No que
          diz respeito a você e à Oiro, qualquer Conteúdo será não confidencial
          e não proprietário e não seremos responsáveis por qualquer uso ou
          divulgação do Conteúdo.
        </Typography>
        <Typography>
          Você reconhece e concorda que o seu relacionamento com O Oiro não é
          confidencial, fiduciário ou qualquer outro tipo de relacionamento
          especial, e que a sua decisão de enviar qualquer Conteúdo não coloca O
          Oiro em uma posição diferente daquela ocupada por membros do público
          em geral, inclusive com relação ao seu Conteúdo. O seu Conteúdo não
          estará sujeito a qualquer obrigação de confidencialidade da parte do
          Oiro, a não ser a determinada em nossa Política de privacidade e nos
          seus controles de privacidade, e O Oiro não será responsável por
          qualquer uso ou divulgação do Conteúdo fornecido por você.
        </Typography>
        <Typography>
          Os Serviços podem oferecer a você a capacidade de marcar certos
          Conteúdos que você envia aos Serviços como privados, públicos ou
          disponíveis somente a determinados usuários dos Serviços. O Oiro
          manterá a privacidade de tais Conteúdos de acordo com as suas
          seleções. Entretanto, se você não optar por marcar o Conteúdo como
          privado ou disponível para um grupo limitado de atletas, ou
          posteriormente alterar tal designação para permitir que tal Conteúdo
          esteja disponível para qualquer pessoa, você será responsável pela
          natureza pública do conteúdo.
        </Typography>
        <Typography>
          Você nos dá permissão para usar seu nome de perfil, foto de perfil e
          informações sobre as suas atividades e as ações que realizou,
          inclusive seu uso de produtos, serviços ou dispositivos de terceiros,
          em anúncios, ofertas e outros conpos comerciais no serviço do Oiro sem
          compensação para você. Por exemplo, podemos mostrar aos seguidores do
          Oiro que você usa um determinado dispositivo, produto ou serviço
          disponível de uma marca que nos pagou para veicular seus anúncios nO
          Oiro. Você pode ajustar as configurações para impedir que o seu nome
          de perfil e foto de perfil apareçam em um anúncio.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>Conduta</Typography>
          <Typography>
            Esperamos que você siga as Normas da comunidade Oiro. Os Serviços
            destinam-se a seu uso pessoal e não comercial. Você não pode
            modificar, copiar, distribuir, transmitir, exibir, executar,
            reproduzir, publicar, licenciar, criar trabalhos derivados,
            transferir ou vender para nenhum fim comercial qualquer parte dos
            Serviços, uso dos Serviços ou acesso ao Conteúdo. Você não pode usar
            os Serviços ou auxiliar ou incentivar outra parte a envolver-se em
            qualquer uma das seguintes Atividades proibidas abaixo:
          </Typography>
        </Box>
        <Typography>
          Cópia, recorte ou espelhamento de qualquer parte dos Serviços;
        </Typography>
        <Typography>
          Acesso aos Serviços para monitorar sua disponibilidade, desempenho ou
          funcionalidade;
        </Typography>
        <Typography>Permissão do acesso de terceiros aos Serviços;</Typography>
        <Typography>
          Uso, cópia, modificação, criação de trabalho derivado, engenharia
          reversa, descompilação ou tentativa de extrair de outra forma o código
          fonte do software de base dos Serviços ou qualquer parte dele, a menos
          que expressamente permitido ou exigido por lei e, de qualquer forma,
          sem notificar O Oiro previamente por escrito;
        </Typography>
        <Typography>
          Publicação, transmissão, distribuição ou armazenamento de conteúdo,
          material, informações ou dados que: (1) sejam ilegais, obscenos,
          difamatórios, ameaçadores, perturbadores, abusivos ou de incitação ao
          ódio, ou que preguem a violência; (2) sejam prejudiciais ou interfiram
          nos Serviços ou nas redes, equipamentos, aplicativos, serviços ou
          websites de terceiros (por exemplo: vírus, worms, cavalos de Troia,
          etc.); (3) infrinjam, reduzam, apropriem-se indevidamente ou de outra
          forma violem quaisquer direitos de privacidade, propriedade
          intelectual, publicidade ou outros direitos pessoais, incluindo, sem
          limitação, direitos autorais, patentes, marcas comerciais, segredos
          comerciais ou outras informações proprietárias; ou (4) sejam
          fraudulentos ou contenham declarações, alegações ou afirmações falsas,
          enganosas ou errôneas (tais como “phishing”);
        </Typography>
        <Typography>
          Tentativa de interromper, prejudicar, afetar ou violar a integridade
          ou a segurança dos Serviços ou computadores, serviços, Contas ou redes
          de qualquer outra parte (inclusive, sem limitação, “hacking”, ataques
          de “negação de serviço”, etc.), e qualquer atividade que geralmente
          antecede tentativas de violar a segurança, como varredura, sondagem ou
          outra atividade de avaliação e teste de vulnerabilidade, ou
          envolvimento ou permissão de atividades de rede ou hospedagem que
          resulte na inclusão em lista negra ou em outros bloqueios do espaço de
          protocolo de Internet do Oiro;
        </Typography>
        <Typography>
          Não cumprimento do pagamento de despesas ou taxas devidas por você
          referentes aos Serviços; Prática de atos que possam prejudicar menores
          de idade;
        </Typography>
        <Typography>
          Distribuição ou divulgação de qualquer parte dos Serviços em qualquer
          meio, inclusive, sem limitação, por “scraping” automatizado ou não
          automatizado;
        </Typography>
        <Typography>
          Utilização de qualquer sistema automatizado, inclusive, sem limitação,
          “robôs”, “aranhas”, “leitores offline”, etc., para acessar os Serviços
          de uma forma que envie mais mensagens de solicitação à Oiro do que um
          ser humano pode razoavelmente produzir no mesmo período de tempo
          usando um navegador da web convencional;
        </Typography>
        <Typography>
          Realização de ação que imponha ou possa impor, a nosso exclusivo
          critério, uma carga despropositada ou desproporcionalmente grande em
          nossa infraestrutura;
        </Typography>
        <Typography>
          Coletar ou colher quaisquer informações relacionadas a um indivíduo
          identificado ou identificável, inclusive nomes de conta e informações
          sobre usuários dos Serviços, a partir dos Serviços;
        </Typography>
        <Typography>
          Utilização dos Serviços para fins de solicitação comercial
          inapropriadas;
        </Typography>
        <Typography>
          Acesso a conteúdo nos Serviços através de qualquer tecnologia ou
          outros meios não fornecidos ou autorizados pelos Serviços;
        </Typography>
        <Typography>
          Envio aos Serviços ou à Oiro de qualquer informação de identificação
          pessoal, exceto conforme necessário para o estabelecimento e a
          operação da sua conta;
        </Typography>
        <Typography>
          Envio aos Serviços ou à Oiro de informações que possam ser protegidas
          contra divulgação pela legislação aplicável;
        </Typography>
        <Typography>
          Desconsideração das medidas que podemos usar para prevenir ou
          restringir o acesso aos Serviços, incluindo, sem limitação, recursos
          que previnem ou restringem o uso ou a cópia de qualquer conteúdo ou
          que impõem limitações ao uso dos Serviços ou de seu conteúdo;
        </Typography>
        <Typography>
          Violação de qualquer lei, estatuto, decreto ou regulamentação, ou
          promoção de qualquer conduta que constitua crime ou dê origem a uma
          responsabilidade civil;
        </Typography>
        <Typography>
          Remoção de avisos de direitos autorais, marca comercial ou outros
          direitos de propriedade contidos nos Serviços; ou Aplicação de
          qualquer forma de monitoramento de rede ou execução de um analisador
          de rede ou sniffer de pacote ou outra tecnologia para interceptar,
          decodificar, extrair ou exibir pacotes usados na comunicação entre os
          servidores dos Serviços ou quaisquer dados não destinados a você.
        </Typography>
        <Typography>
          Você recebe um direito limitado e não exclusivo de criar um hiperlink
          de po aos Serviços somente para uso não comercial, contanto que tal
          link não represente O Oiro ou qualquer um de seus produtos ou serviços
          de maneira falsa, enganosa, depreciativa ou, de alguma forma,
          difamatória, e contanto que o site vinculado não contenha material
          pornográfico, ilegal, ofensivo, perturbador ou questionável. A você
          também é concedido o direito de implementar quaisquer feeds RSS
          localizados nos Serviços para uso pessoal e não comercial, unicamente
          da forma descrita nos Serviços. Nós nos reservamos o direito de
          revogar essas licenças em geral, ou o seu direito de usar links ou
          feeds RSS específicos, a qualquer momento, com ou sem causa. Você
          compreende que o uso de determinados recursos dos Serviços poderá
          exigir que você adquira equipamentos ou materiais de terceiros (por
          exemplo, sistemas GPS). Embora O Oiro possa recomendar equipamentos ou
          materiais de determinados fornecedores, O Oiro não se responsabiliza
          por sua aquisição ou uso de equipamentos ou materiais de terceiros e
          não garante que equipamentos ou materiais de terceiros funcionarão com
          os Serviços ou não conterão erros.
        </Typography>
        <Typography>
          Você compreende que é responsável por quaisquer cobranças associadas
          ao envio de comunicações pelo seu dispositivo. Por este instrumento,
          você reconhece que tem o direito de se comunicar com seus contatos por
          meio dos Serviços.
        </Typography>
        <Typography>
          Você declara e garante que: (i) está autorizado(a) a criar a sua
          conta, seja individualmente ou em nome de uma organização; (ii) detém
          o Conteúdo publicado por você nos Serviços ou por meio deles, ou de
          outra forma tem o direito de conceder os direitos e licenças
          estabelecidos nestes Termos; (iii) a publicação e o uso do seu
          Conteúdo nos Serviços ou por meio deles não viola, apropria-se
          indevidamente ou infringe, agora ou futuramente, os direitos de
          terceiros, inclusive, sem limitação, direitos de privacidade e
          proteção de dados, direitos de publicidade, direitos autorais, marcas
          comerciais e/ou outros direitos de propriedade intelectual; e (iv)
          você concorda em pagar todos os royalties, taxas e outras quantias
          devidas por conta do Conteúdo que você publica nos Serviços ou por
          meio deles.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Interações com atletas
          </Typography>
          <Typography>
            Os Serviços funcionam como um ponto para conectar os atletas em um
            ambiente virtual de informações. Como um facilitador neutro, O Oiro
            não está diretamente envolvida nas interações reais entre os atletas
            que usam os Serviços. Consequentemente, O Oiro não tem controle
            sobre a veracidade, precisão, qualidade, legalidade ou segurança das
            publicações feitas por atletas que usam os Serviços. O Oiro não terá
            a responsabilidade de confirmar a identidade dos atletas. O Oiro
            também não terá a responsabilidade de confirmar ou verificar as
            qualificações, o histórico ou as habilidades dos atletas que usam os
            Serviços. Você deverá sempre usar o bom senso e agir com
            discernimento ao lidar com qualquer atleta que use os Serviços.
          </Typography>
        </Box>

        <Typography>
          Se você optar por usar nossos recursos para informar seus contatos
          sobre os Serviços, acompanhar suas atividades pelo Oiro ou
          compartilhar suas informações com outras pessoas, O Oiro poderá
          solicitar que você forneça informações de contato. O Oiro poderá
          entrar em contato com seu amigo por e-mail ou mensagem de po enviados
          uma única vez. Você garante que está autorizado a fornecer quaisquer
          informações de contato de terceiros que enviar à Oiro, que está
          autorizado a usar tais informações para entrar em contato (inclusive
          para O Oiro entrar em contato em seu nome) com os terceiros e que O
          Oiro pode processá-las de acordo com a Política de privacidade.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>Terceiros</Typography>
          <Typography>
            Os produtos e serviços de terceiros disponibilizados nos Serviços
            são desenvolvidos e oferecidos diretamente pelos respectivos
            terceiros. Quando você busca ou adquire tais produtos ou serviços,
            reconhece que está contratando diretamente os terceiros e não O
            Oiro. Sua participação ou interação com promoções de terceiros,
            encontradas nos Serviços ou por meio deles, inclusive o pagamento e
            fornecimento de bens ou serviços, e quaisquer outros termos, ocorrem
            unicamente entre você e os terceiros. Você não é obrigado a usar ou
            negociar com terceiros apresentados nos Serviços. VOCÊ CONCORDA QUE
            O OIRO NÃO SERÁ RESPONSABILIZADA POR PERDAS OU DANOS DE QUALQUER
            TIPO INCORRIDOS EM SUAS NEGOCIAÇÕES COM PROVEDORES DE SERVIÇOS
            TERCEIRIZADOS OU ANUNCIANTES DISPONÍVEIS POR MEIO DOS SERVIÇOS.
          </Typography>
        </Box>
        <Typography>
          O Oiro ou terceiros poderão fornecer links a outros sites da Internet
          ou recursos por meio dos Serviços. O Oiro não endossa e não se
          responsabiliza por qualquer conteúdo, informação, publicidade, produto
          ou outros materiais em tais sites ou recursos ou neles disponíveis.
          Você reconhece e concorda que O Oiro não é responsável pela
          disponibilidade de tais sites ou recursos externos.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Comunicações eletrônicas
          </Typography>
          <Typography>
            Os Serviços poderão oferecer a você a capacidade de enviar ou
            publicar mensagens em fóruns ou salas de bate-papo, falar por meio
            de conexões de voz pela Internet ou enviar mensagens e comunicações
            semelhantes a provedores de serviços terceirizados, anunciantes,
            seus contatos pessoais, outros atletas e/ou O Oiro. Você concorda em
            usar os métodos de comunicação disponíveis nos Serviços somente para
            enviar comunicações e materiais relacionados ao assunto para o qual
            O Oiro forneceu o método de comunicação, e concorda que todas essas
            comunicações da sua parte serão consideradas seu Conteúdo e deverão
            se sujeitar e ser regidas pelos Termos e pela lei aplicável
            (incluindo as leis que regulam comunicações de marketing direto que
            você deve cumprir, conforme aplicável). Ao usar os métodos de
            comunicações disponíveis nos Serviços, você concorda que (a) todos
            os métodos de comunicações constituem um meio de comunicação
            público, e não privado, entre você e a outra parte ou partes, (b) as
            comunicações enviadas ou recebidas de provedores de serviços
            terceirizados, anunciantes ou outros terceiros não são endossadas,
            patrocinadas ou aprovadas pelO Oiro (a menos que declarado
            expressamente de outra forma pelO Oiro) e (c) as comunicações não
            são rotineiramente revisadas antes ou depois, selecionadas,
            arquivadas ou monitoradas pelO Oiro de maneira alguma, embora O Oiro
            reserve-se o direito de fazê-lo a qualquer momento, a seu exclusivo
            critério. Você concorda que todas as notificações, divulgações e
            outras comunicações que fornecemos a você eletronicamente satisfarão
            qualquer requisito legal de que tal comunicação tenha sido feita por
            escrito.
          </Typography>
        </Box>

        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Direitos de propriedade
          </Typography>
          <Typography>
            Você reconhece e concorda que os Serviços, qualquer software
            necessário usado com os Serviços (se houver), quaisquer dados
            agregados baseados no Conteúdo dos Serviços e qualquer Conteúdo
            disponível ou disponibilizado nos Serviços contêm informações
            confidenciais protegidas pelas leis de propriedade intelectual e
            outras leis aplicáveis. Exceto conforme expressamente permitido pela
            legislação aplicável ou autorizado pelO Oiro ou por provedores de
            serviços terceirizados ou anunciantes cabíveis, você concorda em não
            modificar, alugar, arrendar, emprestar, vender, distribuir ou criar
            trabalhos derivados com base nos Serviços, no software ou no
            Conteúdo disponível nos Serviços (com exceção do Conteúdo que você
            possa enviar), seja no todo ou em parte.
          </Typography>
        </Box>
        <Typography>
          O Oiro concede a você, de forma pessoal, revogável, intransferível,
          não sublicenciável e não exclusiva, o direito e a licença para acessar
          e usar os Serviços, contanto que você não (e não permita que nenhum
          terceiro) copie, modifique, crie trabalho derivado, faça engenharia
          reversa, faça montagem reversa ou tente, de outra forma, descobrir
          qualquer código fonte, venda, atribua, sublicencie, conceda direito
          real de garantia ou de outra forma transfira qualquer direito nos
          Serviços, exceto onde permitido por lei. Você concorda em não acessar
          os Serviços de nenhuma outra forma a não ser pelas interfaces
          fornecidas pelO Oiro.
        </Typography>
        <Typography>
          O termo OIRO, o logotipo Oiro e outros logotipos e nomes de produtos e
          serviços do Oiro são marcas comerciais exclusivas ou são de
          propriedade do Oiro e seu representante, e você não pode usar nem
          exibir tais marcas comerciais de forma alguma sem o consentimento
          prévio por escrito do Oiro. Quaisquer marcas comerciais ou de serviço
          de terceiros exibidas nos Serviços pertencem aos seus respectivos
          proprietários.
        </Typography>
        <Typography>
          O Oiro reserva-se todos os direitos não concedidos expressamente neste
          documento.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Reivindicações de infração
          </Typography>
          <Typography>
            O Oiro analisa alegações de infração de marca comercial. Se você
            acredita de boa fé que os materiais hospedados pelO Oiro infringem
            seus direitos de marca comercial ou direitos autorais, você (ou seu
            agente) pode nos enviar uma notificação solicitando que o material
            seja removido ou o acesso a ele seja bloqueado. A notificação deverá
            incluir as seguintes informações: (a) uma assinatura física ou
            eletrônica da pessoa autorizada a agir em nome do detentor de um
            direito exclusivo supostamente infringido; (b) identificação do
            trabalho com direitos autorais ou marca comercial supostamente
            infringido (ou, se vários trabalhos com direitos autorais ou marcas
            comerciais localizados nos Serviços estiverem incluídos em uma única
            notificação, uma lista representativa de tais trabalhos); (c)
            identificação do material que supostamente infringe ou é o objeto da
            atividade infratora, e informações suficientes para possibilitar que
            O Oiro localize o material nos Serviços; (d) o nome, endereço,
            número de telefone e endereço de e-mail (se disponíveis) da parte
            requerente; (e) uma declaração de que a parte requerente acredita de
            boa fé que o uso do material da maneira alegada não é autorizado
            pelo detentor dos direitos autorais ou marca comercial, seu agente
            ou a lei; e (f) uma declaração de que as informações na notificação
            são precisas e, que a parte requerente está autorizada a agir em
            nome do detentor de um direito exclusivo supostamente infringido.
            Esteja ciente de que O Oiro não responderá a queixas que não atendam
            a esses requisitos. Caso O Oiro determine que os materiais que você
            alegar que estão infringindo seus direitos autorais ou marca
            comercial não precisam ser removidos, O Oiro somente removerá esses
            materiais por ordem judicial que declare que o conteúdo ou o uso dos
            materiais é ilegal.
          </Typography>
        </Box>

        <Typography>
          Se você acredita de boa fé que uma notificação de violação de direitos
          autorais foi indevidamente apresentada contra você, permitimos que
          você nos envie uma contranotificação. As contranotificações devem
          incluir as seguintes informações: (a) seu nome, endereço e número de
          telefone; (b) a fonte do conteúdo que foi removido; (c) uma
          declaração, de que você acredita de boa fé que o conteúdo foi
          indevidamente removido; (d) uma declaração de que você consente com a
          jurisdição da comarca na qual seu endereço está localizado; de
          qualquer comarca em que O Oiro se encontre, e que você aceitará a
          citação da pessoa que apresentou a queixa original; e (e) uma
          assinatura física ou eletrônica (por exemplo, a digitação do seu nome
          completo).
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Agente que recebe notificações com alegação de violação:{' '}
          </Typography>
          <Typography>Oiro</Typography>
          <Typography>
            Rua Inácio Martins, 162 / Curitiba -PR / Brasil
          </Typography>
          <Typography>sac@oiro.com.br / (041) 9.9154-3447</Typography>
          <Typography>comentários</Typography>
          <Typography>
            Agradecemos seus comentários, feedback, sugestões e outras
            comunicações sobre os Serviços e as informações e serviços que
            disponibilizamos por meio dos Serviços (coletivamente,
            “Comentários”). Se enviar Comentários, você concede à Oiro, por este
            instrumento, uma licença mundial, não exclusiva, transferível,
            atribuível, sublicenciável, perpétua, irrevogável, livre de
            royalties para copiar, distribuir, criar trabalhos derivados, exibir
            e reproduzir publicamente e de outra forma explorar tais comentários
            e usar, fazer, mandar fazer, vender, pôr à venda, importar e
            exportar produtos e serviços baseados nestes comentários. Por essa
            razão, pedimos que você não envie à Oiro nenhum comentário que não
            queira licenciar para nós conforme estabelecido acima.
          </Typography>
        </Box>

        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Isenção de responsabilidade e garantias
          </Typography>
          <Typography>
            AS INFORMAÇÕES, PROGRAMAS DE SOFTWARE, PRODUTOS, SERVIÇOS E
            CONTEÚDOS DISPONÍVEIS POR MEIO DOS SERVIÇOS SÃO FORNECIDOS A VOCÊ
            “NO ESTADO EM QUE SE ENCONTRAM” E SEM GARANTIA. O OIRO E SUAS
            SUBSIDIÁRIAS, AFILIADAS, EXECUTIVOS, FUNCIONÁRIOS, AGENTES,
            PARCEIROS E LICENCIADORES, PELO PRESENTE, REJEITAM TODAS AS
            GARANTIAS RELATIVAS A TAIS INFORMAÇÕES, PROGRAMAS DE SOFTWARE,
            PRODUTOS, SERVIÇOS E CONTEÚDOS INCLUSIVE, SEM LIMITAÇÃO, TODAS AS
            GARANTIAS IMPLÍCITAS E CONDIÇÕES DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UM
            FIM ESPECÍFICO, TITULARIDADE E LICITUDE. O OIRO E SUAS SUBSIDIÁRIAS,
            AFILIADAS, EXECUTIVOS, FUNCIONÁRIOS, AGENTES, PARCEIROS E
            LICENCIADORES NÃO OFERECEM QUALQUER GARANTIA DE QUE: (a) O SERVIÇO
            ATENDERÁ SEUS REQUISITOS; (b) SEU CONTEÚDO ESTARÁ DISPONÍVEL OU O
            SERVIÇO SERÁ ININTERRUPTO, PRESTADO EM TEMPO HÁBIL, SEGURO OU SEM
            ERROS; (c) OS RESULTADOS QUE PODEM SER OBTIDOS COM O USO DO SERVIÇO
            SERÃO PRECISOS E CONFIÁVEIS; (d) A QUALIDADE DE QUAISQUER PRODUTOS,
            SERVIÇOS, INFORMAÇÕES OU OUTROS MATERIAIS ADQUIRIDOS OU OBTIDOS POR
            VOCÊ POR MEIO DO SERVIÇO ATENDERÃO SUAS EXPECTATIVAS; E (e)
            QUAISQUER ERROS NOS SERVIÇOS SERÃO CORRIGIDOS.
          </Typography>
        </Box>

        <Typography>
          VOCÊ CONCORDA EXPRESSAMENTE QUE O OIRO NÃO ESTÁ FORNECENDO
          ACONSELHAMENTO MÉDICO POR MEIO DOS SERVIÇOS. O CONTEÚDO FORNECIDO POR
          MEIO DOS SERVIÇOS, INCLUINDO TODO O pO, FOTOGRAFIAS, IMAGENS,
          ILUSTRAÇÕES, GRÁFICOS, ÁUDIO, VÍDEO E CLIPES AUDIOVISUAIS, E OUTROS
          MATERIAIS, FORNECIDOS POR NÓS OU POR OUTROS USUÁRIOS QUE TENHAM UMA
          CONTA OU TERCEIROS NÃO SE DESTINA AO USO E NÃO DEVE SER USADO PARA
          SUBSTITUIR (a) ACONSELHAMENTO DO SEU MÉDICO OU DE OUTROS PROFISSIONAIS
          DE SAÚDE, (b) CONSULTA, TELEFONEMA OU EXAME COM O SEU MÉDICO OU OUTROS
          PROFISSIONAIS DE SAÚDE, OU (c) INFORMAÇÕES CONTIDAS EM EMBALAGENS OU
          RÓTULOS DE QUALQUER PRODUTO. CASO TENHA PERGUNTAS RELACIONADAS À
          SAÚDE, LIGUE PARA OU CONSULTE O SEU MÉDICO OU OUTRO PROFISSIONAL DE
          SAÚDE PRONTAMENTE. EM CASO DE EMERGÊNCIA, LIGUE PARA O SEU MÉDICO OU O
          190 IMEDIATAMENTE. VOCÊ NUNCA DEVE DESCONSIDERAR ORIENTAÇÕES MÉDICAS
          OU DEMORAR A BUSCAR ACONSELHAMENTO MÉDICO POR CAUSA DE QUALQUER
          CONTEÚDO APRESENTADO NOS SERVIÇOS, E VOCÊ NÃO DEVE USAR OS SERVIÇOS OU
          QUALQUER CONTEÚDO DOS SERVIÇOS PARA DIAGNOSTICAR OU TRATAR DE UM
          PROBLEMA DE SAÚDE. A TRANSMISSÃO E O RECEBIMENTO DO NOSSO CONTEÚDO,
          TOTAL OU PARCIAL, OU A COMUNICAÇÃO VIA INTERNET, E-MAIL OU OUTRO MEIO
          NÃO CONSTITUI NEM CRIA UM RELACIONAMENTO MÉDICO-PACIENTE,
          TERAPEUTA-PACIENTE OU OUTRO RELACIONAMENTO COM PROFISSIONAL DE SAÚDE
          ENTRE VOCÊ E O OIRO.
        </Typography>
        <Typography>
          VOCÊ CONCORDA EXPRESSAMENTE QUE SUAS ATIVIDADES ATLÉTICAS (INCLUINDO,
          SEM LIMITAÇÃO, CICLISMO, CORRIDA OU A UTILIZAÇÃO DE UM PLANO DE
          TREINAMENTO DO OIRO OFERECIDO NOS SERVIÇOS) ENVOLVEM CERTOS RISCOS
          INERENTES E SIGNIFICATIVOS DE DANOS MATERIAIS, LESÕES CORPORAIS OU
          MORTE E QUE VOCÊ ASSUME TODOS OS RISCOS CONHECIDOS OU DESCONHECIDOS
          ASSOCIADOS A ESTAS ATIVIDADES AINDA QUE SEJAM CAUSADOS TOTAL OU
          PARCIALMENTE PELA AÇÃO, OMISSÃO OU NEGLIGÊNCIA DO OIRO OU PELA AÇÃO,
          OMISSÃO OU NEGLIGÊNCIA DE OUTROS.
        </Typography>
        <Typography>
          VOCÊ CONCORDA EXPRESSAMENTE QUE O OIRO NÃO ASSUMIRÁ RESPONSABILIDADE
          PELA INSPEÇÃO, SUPERVISÃO, PREPARAÇÃO OU REALIZAÇÃO DE QUALQUER
          CORRIDA, COMPETIÇÃO, DESAFIO OU ATIVIDADE EM GRUPO QUE UTILIZE OS
          SERVIÇOS, INCLUSIVE QUALQUER UMA QUE SEJA ORGANIZADA POR UM
          ADMINISTRADOR DE CLUBE.
        </Typography>
        <Typography>
          VOCÊ CONCORDA EXPRESSAMENTE EM ISENTAR O OIRO, SUAS SUBSIDIÁRIAS,
          AFILIADAS, EXECUTIVOS, AGENTES, REPRESENTANTES, FUNCIONÁRIOS,
          PARCEIROS E LICENCIADORES (AS “PARTES ISENTAS”) DE TODA E QUALQUER
          RESPONSABILIDADE COM RELAÇÃO ÀS SUAS ATIVIDADES ATLÉTICAS E/OU USO DOS
          SITES, APLICATIVOS MÓVEIS, CONTEÚDO, SERVIÇOS OU PRODUTOS DO OIRO
          (INCLUSIVE, ENTRE OUTROS, QUALQUER PLANO DE TREINAMENTO DO OIRO), E
          PROMETE NÃO PROCESSAR AS PARTES ISENTAS POR QUAISQUER REIVINDICAÇÕES,
          AÇÕES, LESÕES, DANOS OU PERDAS ASSOCIADOS A ESSE USO. VOCÊ CONCORDA
          TAMBÉM QUE, EM CIRCUNSTÂNCIA ALGUMA, AS PARTES ISENTAS SERÃO
          RESPONSÁVEIS PERANTE VOCÊ OU QUAISQUER TERCEIROS POR DANOS DIRETOS,
          INDIRETOS, PUNITIVOS, INCIDENTAIS, ESPECIAIS OU CONSEQUENTES
          DECORRENTES OU DE ALGUMA FORMA RELACIONADOS COM (a) SEU USO OU USO
          INADEQUADO DOS SERVIÇOS, (b) SEU USO OU USO INADEQUADO DE EQUIPAMENTOS
          OU PROGRAMAS CRIADOS OU LICENCIADOS PELO OIRO (INCLUSIVE, ENTRE
          OUTROS, QUALQUER PLANO DE TREINAMENTO DO OIRO) DURANTE A REALIZAÇÃO DE
          ATIVIDADES ATLÉTICAS, (c) SUAS NEGOCIAÇÕES COM PROVEDORES DE SERVIÇOS
          TERCEIRIZADOS OU ANUNCIANTES DISPONÍVEIS POR MEIO DOS SERVIÇOS, (d)
          QUALQUER ATRASO OU INCAPACIDADE DE USAR OS SERVIÇOS, OU (e) QUAISQUER
          INFORMAÇÕES, PROGRAMAS DE SOFTWARE, PRODUTOS, SERVIÇOS OU CONTEÚDOS
          OBTIDOS ATRAVÉS DOS SERVIÇOS, SEJA COM BASE EM CONTRATO, ATO ILÍCITO,
          RESPONSABILIDAE ESTRITA, ENTRE OUTROS, MESMO QUE O OIRO TENHA SIDO
          AVISADA DA POSSIBILIDADE DE DANOS.
        </Typography>
        <Typography>
          O OIRO NÃO ENDOSSA O SEU CONTEÚDO NEM O CONTEÚDO DE QUALQUER OUTRO
          ATLETA E ESPECIFICAMENTE ISENTA-SE DE QUALQUER RESPONSABILIDADE
          PERANTE PESSOAS OU ENTIDADES POR QUALQUER PERDA, DANO (SEJA ELE REAL,
          CONSEQUENTE, INDIRETO, PUNITIVO OU OUTRO), LESÃO, REIVINDICAÇÃO,
          RESPONSABILIDADE OU OUTRA CAUSA DE QUALQUER TIPO OU CARÁTER BASEADA OU
          RESULTANTE DE QUALQUER CONTEÚDO DOS SERVIÇOS.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>Indenização</Typography>
          <Typography>
            Você concorda em indenizar e isentar O Oiro e suas subsidiárias,
            afiliadas, executivos, agentes, representantes, funcionários,
            parceiros e licenciadores de responsabilidade por qualquer
            reivindicação ou demanda, inclusive honorários advocatícios
            razoáveis incorridos por quaisquer terceiros devidos ao ou
            resultantes do Conteúdo enviado, publicado, transmitido ou
            disponibilizado por você por meio dos Serviços, do seu uso dos
            Serviços, das suas atividades atléticas que geraram o Conteúdo que
            você publicou ou tentou publicar nos Serviços (inclusive, entre
            outros, atividades atléticas relacionadas com competições, corridas,
            passeios em grupo ou outros eventos que O Oiro patrocine, organize,
            participe ou cujos Serviços sejam usados em conexão), sua conexão
            com os Serviços, sua violação dos Termos, sua violação de quaisquer
            leis de proteção ou privacidade de dados ou sua violação de
            quaisquer direitos de outra pessoa ou entidade. Os seus direitos com
            respeito à Oiro não são modificados pela indenização referida.
          </Typography>
        </Box>
        <Typography style={{ fontWeight: 'bold' }}>
          RESOLUÇÃO DE DISPUTAS{' '}
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>Arbitragem</Typography>
          <Typography>
            As partes se empenharão ao máximo para resolver qualquer disputa,
            reivindicação, dúvida ou divergência diretamente em negociações de
            boa fé, que serão um pré-requisito para qualquer uma das partes que
            iniciar uma ação legal ou arbitragem. Exceto as disputas
            relacionadas à propriedade intelectual do Oiro (como marcas
            comerciais, identidade visual, nomes de domínio, segredos comerciais
            e patentes) ou se você renunciar a este acordo de arbitragem, todas
            as reivindicações decorrentes ou referentes a este Acordo e ao seu
            uso dos Serviços deverão ser resolvidas de forma definitiva por
            arbitragem, conforme suas disposições e procedimentos para disputas
            relacionadas ao consumidor, excluindo quaisquer regras ou
            procedimentos que regem ou permitem ações coletivas. O árbitro, terá
            autoridade exclusiva para resolver todas as disputas decorrentes de
            ou relacionadas a este acordo, inclusive, sem limitação, qualquer
            reivindicação de que este acordo é, no todo ou em parte, nulo ou
            anulável. O árbitro terá o poder de conceder qualquer reparação que
            estaria disponível em um tribunal; contanto, porém, que o árbitro
            não tenha autoridade para conceder indenizações, remediações ou
            adjudicações conflitantes com este acordo. A sentença do árbitro
            deverá ser vinculativa às partes e poderá ser incluída como
            julgamento em qualquer tribunal de jurisdição competente. Na
            extensão em que a taxa de apresentação de arbitragem exceder o custo
            de registro de uma ação, O Oiro arcará com os custos adicionais.
            Você e O Oiro, por meio deste documento, renunciam expressamente ao
            direito a julgamento com júri. Você também concorda em não
            participar de reivindicações levadas a um advogado particular ou em
            capacidade representativa, ou reivindicações consolidadas envolvendo
            a conta de outra pessoa, caso O Oiro seja uma das partes no
            processo. Esta disposição de resolução de disputas será regida pela
            Lei 9.307 de Arbitragem.
          </Typography>
        </Box>
        <Typography>
          Fica também entendido que, em alguns casos, os custos da arbitragem
          podem exceder os custos do litígio e que o direito ao acesso a
          evidências pode ser mais limitado na arbitragem do que na ação
          judicial.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Renúncia ao direito de ação coletiva{' '}
          </Typography>
          <Typography>
            As partes também concordam que qualquer arbitragem será realizada
            somente em suas capacidades individuais, e não como uma ação
            coletiva ou outra ação de representação. Se um tribunal ou árbitro
            determinar que a renúncia ao direito à ação coletiva definida nesta
            seção é inválida ou inexequível por qualquer motivo, ou que uma
            arbitragem pode ser realizada com base na classe, a disposição de
            arbitragem definida anteriormente deverá ser considerada nula e
            inválida em sua totalidade e será considerado que as partes não
            concordaram em recorrer à arbitragem.
          </Typography>
        </Box>
        <Typography>
          Exceção — Litígio de reivindicações em juizado especial de pequenas
          causas{' '}
        </Typography>
        <Typography>
          Não obstante a decisão das partes de resolver todas as disputas por
          arbitragem, qualquer uma das partes também poderá buscar uma decisão
          em um tribunal de pequenas causas para as disputas ou reivindicações
          dentro do escopo dessa jurisdição do tribunal.
        </Typography>
        <Typography>Prazo de 30 dias para cancelamento</Typography>
        <Typography>
          Você tem o direito de optar por não recorrer à arbitragem ou não
          sujeitar-se às disposições da arbitragem e da renúncia à ação coletiva
          estabelecidas nesta seção enviando uma notificação por escrito da sua
          decisão ao seguinte endereço:{' '}
          <Typography
            style={{ pDecorationLine: 'underline', fontWeight: 'bold' }}
          >
            Oiro, Inácio Martins, 162 xaxim Cep 81720- 160, Curitiba-PR, Brasil
          </Typography>
          . A notificação deverá ser enviada até 30 dias após o cadastro para
          usar os Serviços. Caso contrário, você estará obrigado a participar em
          arbitragem de disputas de acordo com estes Termos. Caso você opte por
          não sujeitar-se às disposições sobre arbitragem, O Oiro também não
          estará sujeita a elas. Além disso, se optar por não sujeitar-se a
          essas disposições sobre arbitragem, O Oiro poderá cancelar o seu uso
          dos Serviços.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Limitação de tempo para reivindicações
          </Typography>
          <Typography>
            Você concorda que qualquer reivindicação que você possa ter
            decorrente de ou referente ao seu relacionamento com O Oiro e estes
            Termos será apresentada até um ano após o surgimento da
            reivindicação. Caso contrário, ela será permanentemente impedida.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Eleição de legislação e foro
          </Typography>
          <Typography>
            Qualquer ação referente aos Termos, Conteúdo, Serviços e seu
            relacionamento com O Oiro será regida, definida e interpretada
            conforme as leis Brasileiras, sem considerar conflitos de princípios
            legais E ESPECIFICAMENTE NÃO SERÁ REGIDA PELAS CONVENÇÕES DAS NAÇÕES
            UNIDAS SOBRE CONTRATOS PARA A VENDA INTERNACIONAL DE MERCADORIAS, SE
            APLICÁVEL DE OUTRA FORMA. Conforme a Seção intitulada “Resolução de
            disputas”, as partes irrevogavelmente consentem em levar qualquer
            ação necessária para o cumprimento deste Acordo aos tribunais
            Brasileiros. Se uma das partes mover um processo ou ação contra a
            outra para reparação de danos, declaratória ou de outra forma,
            decorrente destes Termos, a parte vitoriosa obterá e receberá da
            outra parte, além de todas as custas e despesas processuais, a
            quantia determinada como honorários advocatícios razoáveis. Exceto
            na extensão proibida pela legislação aplicável, as partes concordam
            que qualquer ação ou causa decorrente ou relacionada ao uso dos
            Serviços ou aos Termos deve ser apresentada dentro de um (1) ano do
            surgimento da ação ou causa, ou será permanentemente impedida.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>Encerramento</Typography>
          <Typography>
            Você concorda que O Oiro pode, em determinadas circunstâncias graves
            e sem notificação prévia, suspender ou encerrar a sua conta e/ou
            acesso aos Serviços imediatamente. Os motivos para tal suspensão ou
            encerramento incluem, sem limitação, (a) violações dos Termos ou de
            outros acordos, políticas e diretrizes incorporados, (b)
            solicitações de autoridades legais ou outros órgãos governamentais,
            (c) solicitação feita por você (exclusões de conta por iniciativa
            própria), (d) interrupção ou modificação substancial dos Serviços
            (ou de qualquer parte deles), (e) problemas técnicos ou de segurança
            inesperados, (f) períodos prolongados de inatividade e/ou (g) falta
            de pagamento de quaisquer taxas devidas por você relativas aos
            Serviços. O encerramento da sua conta pode incluir (x) remoção do
            acesso a todas as opções oferecidas dentro dos Serviços, (y)
            exclusão das suas informações, arquivos e Conteúdos associados à sua
            conta e (z) impedimento da continuidade de uso dos Serviços. Além
            disso, você concorda que todas as suspensões ou os encerramentos com
            causa ocorrerão a exclusivo critério do Oiro e que O Oiro não será
            responsável perante você ou quaisquer terceiros pela suspensão ou
            encerramento da sua conta ou acesso aos Serviços. As seguintes
            Seções sobreviverão ao encerramento de sua conta e/ou dos Termos:
            Conteúdo e Conduta, Clubes, Direitos de Propriedade, Seu Feedback,
            Isenção de Garantias e Responsabilidade, Indenização, Escolha de Lei
            e Fórum e Geral.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>Geral</Typography>
          <Typography>
            Você concorda que nenhuma relação de joint venture, parceria,
            controle conjunto, emprego ou agência existe entre você e O Oiro
            como resultado dos Termos ou do seu uso dos Serviços. Os Termos
            constituem o acordo completo entre você e O Oiro com relação ao seu
            uso dos Serviços. O não exercício ou imposição do Oiro de qualquer
            direito ou disposição dos Termos não constituirá uma renúncia de tal
            direito ou disposição. Se qualquer disposição dos Termos for
            considerada por um tribunal de jurisdição competente como inválida,
            as partes concordarão, ainda assim, que o tribunal deverá se
            empenhar para dar efeito às intenções das partes conforme refletidas
            na disposição, e as outras disposições dos Termos permanecerão em
            pleno vigor e efeito. Você não pode atribuir, delegar ou, de alguma
            forma, transferir sua conta ou suas obrigações de acordo com estes
            Termos sem o consentimento prévio por escrito do Oiro. O Oiro tem o
            direito, a seu exclusivo critério, de transferir ou atribuir seus
            direitos, no todo ou em parte, de acordo com estes Termos e terá o
            direito de delegar ou utilizar contratados terceirizados para
            cumprir seus deveres e obrigações conforme estes Termos e com
            relação aos Serviços. A notificação do Oiro para você por e-mail,
            correio ou avisos, publicações ou links nos Serviços constituirão
            uma notificação aceitável para você de acordo com os Termos. Uma
            versão impressa dos Termos e de qualquer notificação enviada em
            forma eletrônica será aceita em ações judiciais ou administrativas
            decorrentes de ou relativas aos Termos na mesma extensão e sujeito
            às mesmas condições de outros documentos e registros comerciais
            originalmente gerados e mantidos em forma impressa. Os títulos e
            cabeçalhos de seção nos Termos têm fins unicamente práticos e não
            têm efeito legal ou contratual. Quaisquer direitos não concedidos
            expressamente neste documento são reservados.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Modificação dos Termos e serviços
          </Typography>
          <Typography>
            Determinadas disposições dos Termos poderão ser complementadas ou
            substituídas por termos ou avisos legais designados expressamente em
            páginas específicas nos Serviços. O Oiro reserva- se o direito de
            atualizar os Termos a qualquer momento e por qualquer motivo, a seu
            exclusivo critério. O Oiro notificará você sobre quaisquer
            alterações substanciais nos Termos ou em qualquer serviço ou outros
            recursos dos Serviços. Ao continuar acessando ou usando os Serviços
            após fornecermos um aviso de modificação, você concorda em
            sujeitar-se aos Termos modificados. Se os Termos modificados não
            forem aceitáveis para você, seu único recurso é interromper o uso
            dos Serviços.
          </Typography>
        </Box>
        <Typography>
          O Oiro e seus provedores de serviços terceirizados podem fazer
          aprimoramentos e/ou alterações nos Serviços, produtos, aplicativos
          móveis, recursos, programas e preços descritos a qualquer momento e
          por qualquer motivo, a seu exclusivo critério. O aplicativo móvel pode
          baixar e instalar upgrades, atualizações e recursos adicionais para
          melhorar, aprimorar e desenvolver ainda mais os Serviços. O Oiro se
          reserva o direito de, a qualquer momento, modificar ou suspender, em
          caráter temporário ou permanente, os Serviços ou qualquer parte deles
          com ou sem notificação. Você concorda que O Oiro não terá
          responsabilidade perante você ou quaisquer terceiros pela modificação,
          suspensão ou interrupção dos Serviços.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Suporte e dúvidas
          </Typography>
          <Typography>
            Responderemos a quaisquer perguntas sobre os Serviços e estes Termos
            em{' '}
            <a
              href="https://portal.oiro.app/sac"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'blue', pDecorationLine: 'underline' }}
            >
              https://portal.oiro.app/sac
            </a>
          </Typography>
        </Box>
        <Typography style={{ fontWeight: 'bold' }}>
          POLÍTICA DE PRIVACIDADE
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>Introdução</Typography>
          <Typography>
            Nossa política de privacidade (a “Política de privacidade”) explica
            as informações que coletamos, como as usamos e compartilhamos, como
            gerenciar os seus controles de privacidade e os seus direitos com
            relação aos nossos sites e aos aplicativos móveis e serviços
            relacionados (coletivamente, os “Serviços”). Leia também nossos
            Termos de serviço, que definem os termos que regem os Serviços.
          </Typography>
        </Box>
        <Typography>
          O Oiro é sediada no Brasil e nossos Serviços são fornecidos a você
          pelo seu representante, NORMAN BERNARDO FRIESEN.
        </Typography>
        <Typography>
          Perguntas e comentários sobre esta Política de privacidade podem ser
          enviados por correio para o endereço abaixo ou via{' '}
          <a
            href="https://portal.oiro.app/sac"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'blue', pDecorationLine: 'underline' }}
          >
            https://portal.oiro.app/sac
          </a>
        </Typography>
        <Typography>Oiro Ltda.</Typography>
        <Typography>Endereço</Typography>
        <Typography>Curitiba, Paraná</Typography>
        <Typography>Brasil</Typography>
        <Typography>Assunto: Legal</Typography>
        <Typography>juridico@oiro.com</Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Informações que a Oiro coleta
          </Typography>
          <Typography>
            O Oiro coleta informações sobre você, inclusive informações que o
            identifiquem direta ou indiretamente, se você ou outros usuários
            escolherem compartilhá-las com O Oiro. Recebemos informações de
            algumas formas diferentes, inclusive quando você rastreia, conclui
            ou faz upload de atividades usando os Serviços. O Oiro também coleta
            informações sobre como você usa os Serviços. Além disso, há várias
            outras oportunidades para você compartilhar informações sobre si
            mesmo, seus amigos e as suas atividades com O Oiro. Por exemplo:
            Conta, perfil, atividade e informações do usuário.
          </Typography>
        </Box>
        <Typography>
          Nós coletamos informações básicas de conta, como nome, endereço de
          e-mail, data de nascimento, sexo, peso, nome de usuário e senha, que
          ajudam a proteger e fornecer a você acesso aos nossos Serviços.
        </Typography>
        <Typography>
          Informações de perfil, atividades e uso são coletadas quando você opta
          por fazer upload de uma foto, atividade (incluindo informações de
          data, hora e geolocalização, bem como a sua velocidade e ritmo e
          esforço físico percebido) ou publicar, participar de um desafio,
          adicionar seu uso de equipamento, visualizar atividades de outras
          pessoas ou, de outra forma, usar os Serviços.
        </Typography>
        <Typography>
          Nós usamos suas informações de contato para podermos responder às suas
          solicitações de suporte e comentários.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Informações de localização{' '}
          </Typography>
          <Typography>
            Coletamos e processamos informações de localização quando você se
            inscreve e usa os Serviços. Nós não rastreamos a localização do seu
            dispositivo enquanto você não está usando o Oiro, mas, para fornecer
            os Serviços básicos do Oiro, é necessário rastrear a localização do
            dispositivo enquanto você usa o Oiro. Se desejar interromper o
            rastreamento de localização de dispositivo, você pode ajustar as
            configurações do seu dispositivo a qualquer momento.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Conteúdo que você compartilha{' '}
          </Typography>
          <Typography>
            Coletamos informações de fotos, publicações, comentários, elogios,
            classificações, avaliações e outros conteúdos que você compartilhar
            nos Serviços, inclusive quando você participar de eventos de
            parceiros ou criar segmentos ou rotas.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Informações de contatos{' '}
          </Typography>
          <Typography>
            Você pode escolher adicionar as informações dos seus contatos
            conectando os contatos do seu dispositivo móvel e contas de rede
            social à Oiro. Se escolher compartilhar seus contatos com O Oiro, O
            Oiro, de acordo com as suas instruções, acessará e armazenará as
            informações dos seus contatos para identificar conexões e ajudá-lo a
            se conectar com eles. Saiba mais sobre como coletamos informações
            sobre os seus contatos, como usamos essas informações e os controles
            disponíveis para você.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Dispositivos e apps conectados
          </Typography>
          <Typography>
            O Oiro coleta informações dos dispositivos e aplicativos que você
            conectar ao Oiro e as informações desses dispositivos e aplicativos
            serão transmitidas para O Oiro.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Informações sobre saúde{' '}
          </Typography>
          <Typography>
            O Oiro pode coletar ou inferir informações sobre saúde. Determinadas
            informações sobre saúde podem ser inferidas de fontes como
            frequência cardíaca ou outras medições, inclusive potência, cadência
            e peso ou outros indicadores. Antes de poder fazer upload de
            informações sobre saúde para o Oiro, você deve dar seu consentimento
            explícito para o processamento dessas informações sobre saúde pelo
            Oiro. Você pode retirar o seu consentimento para o Oiro processar as
            suas informações de saúde a qualquer momento.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Informações de pagamento{' '}
          </Typography>
          <Typography>
            Quando você fizer um pagamento no Oiro, poderá fornecer informações
            de pagamento, como o seu cartão de pagamento ou outros detalhes de
            pagamento. Utilizamos serviços de pagamento terceirizados em
            conformidade com o setor de cartões de pagamento e não armazenamos
            suas informações de cartão de crédito.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Contas de terceiros{' '}
          </Typography>
          <Typography>
            O Oiro permite que você se inscreva e faça login nos Serviços usando
            contas que você cria em produtos e serviços de terceiros, como
            Facebook, Google ou Apple (coletivamente, “Contas de terceiros”). Se
            você acessar os Serviços com Contas de terceiros, coletaremos as
            informações que você concordou em disponibilizar, como nome,
            endereço de e-mail, informações de perfil e preferências. Essas
            informações são coletadas pelo provedor da Conta de terceiros e são
            fornecidas à Oiro conforme as políticas de privacidade do provedor.
            Geralmente, você pode controlar as informações que recebemos dessas
            fontes usando os controles de privacidade da sua Conta de terceiros.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Informações técnicas e arquivos de registro
          </Typography>
          <Typography>
            Coletamos informações do seu navegador, computador ou dispositivo
            móvel, o que nos fornece informações técnicas quando você acessa ou
            usa os Serviços. Essas informações técnicas incluem informações
            sobre o dispositivo e rede, cookies, arquivos de registro e
            informações de análise.
          </Typography>
        </Box>
        <Typography>
          Os Serviços utilizam arquivos de registro. As informações armazenadas
          nesses arquivos incluem endereços IP, tipo de navegador, provedor de
          serviços de Internet (ISP), páginas de referência/saída, tipo de
          plataforma, carimbo de data/hora e número de cliques. Essas
          informações são usadas para analisar tendências, administrar e
          proteger os Serviços, acompanhar o movimento do usuário nos dados
          agregados e reunir informações demográficas amplas para uso agregado.
          Endereços IP podem ser vinculados a IDs de sessão, IDs de atleta e
          identificadores de dispositivo.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Outras informações
          </Typography>
          <Typography>
            Nós podemos coletar informações sobre você por meio de terceiros,
            como quando coletamos seu feedback em pesquisas.
          </Typography>
        </Box>
        <Typography>
          Também podemos coletar informações sobre você de outros usuários, como
          quando eles enviam elogios ou fazem comentários sobre suas atividades.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Como a Oiro utiliza as informações{' '}
          </Typography>
          <Typography>
            O Oiro usa as informações que coletamos e recebemos conforme
            descrito abaixo.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Para prestar os Serviços
          </Typography>
          <Typography>
            Usamos as informações que coletamos e recebemos para prestar os
            Serviços, inclusive para que você possa:
          </Typography>
        </Box>
        <Typography>
          Registrar suas atividades e analisar seu desempenho. Por exemplo, para
          comparar seus esforços anteriores, analisar seu treinamento para
          fornecer análises de desempenho interessantes e úteis.
        </Typography>
        <Typography>
          Interagir com outros atletas. Por exemplo, para competir em segmentos,
          participar de clubes, desafios ou eventos, seguir outros atletas e
          usar recursos que ajudam os atletas a interagir uns com os outros,
          como atividades em grupo.
        </Typography>
        <Typography>
          Gerenciar seu treinamento. Por exemplo, para definir metas e usar o
          painel de treinamento. Explorar novos locais. Por exemplo, para
          descobrir rotas ou segmentos onde você pode correr ou pedalar.
        </Typography>
        <Typography>
          Para visualizar suas atividades de novas maneiras. Por exemplo,
          criando mapas pessoais ou usando seu registro de treinamento.
        </Typography>
        <Typography>
          Conforme os seus controles de privacidade, as suas informações,
          incluindo partes do seu perfil, nome de usuário, fotos, usuários que
          você segue e que seguem você, suas atividades, os dispositivos que
          você usa e elogios e comentários que você faz e recebe, serão
          compartilhadas no Oiro para que você possa participar dos Serviços,
          por exemplo, para mostrar a sua posição em um ranking. Determinadas
          informações (por exemplo, seu nome e algumas informações de perfil)
          também estão disponíveis para não usuários na Web. Suas informações
          precisas de localização, como onde você corre ou pedala, também podem
          ser compartilhadas no Oiro ou para não usuários, de acordo com seus
          controles de privacidade.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Para personalizar sua experiência{' '}
          </Typography>
          <Typography>
            Usamos as informações que coletamos sobre você, seus seguidores e
            suas atividades para personalizar a experiência. Por exemplo,
            podemos sugerir segmentos, rotas, desafios ou clubes que possam
            interessar a você, atletas que você queira seguir ou novos recursos
            que queira experimentar. Se soubermos que você gosta de correr,
            podemos informá-lo sobre novas atividades de corrida ou mostrar
            conteúdo patrocinado relacionado a corrida. Se observarmos que você
            corre em uma determinada área, podemos sugerir uma competição de
            corrida nessa área.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Para proteger você e os Serviços
          </Typography>
          <Typography>
            Usamos as informações que coletamos para proteger os usuários, fazer
            cumprir nossos Termos de Serviço e Padrões da Comunidade e promover
            a segurança. Por exemplo, encontramos e removemos conteúdo que viole
            nossos termos, como discurso de ódio ou spam, bem como suspendemos
            ou encerramos contas que compartilham tal conteúdo.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Para melhorar nossos serviços{' '}
          </Typography>
          <Typography>
            Também usamos as informações que coletamos para analisar,
            desenvolver e aprimorar os Serviços. Para fazer isso, O Oiro pode
            usar provedores de análise de terceiros para obter informações sobre
            como os nossos Serviços são usados e para nos ajudar a aprimorar os
            Serviços.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Para se comunicar com você{' '}
          </Typography>
          <Typography>
            Usamos as informações que coletamos para fornecer suporte em
            resposta a suas solicitações e comentários.
          </Typography>
        </Box>

        <Typography>
          Além disso, podemos usar as informações que coletamos para
          comercializar e promover os Serviços, as atividades e os eventos no
          Oiro e outros produtos ou serviços comerciais. Isso inclui marketing e
          comunicações, em que você não tenha optado por não receber tais
          mensagens e notificações.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Para processar sua assinatura{' '}
          </Typography>
          <Typography>
            Usamos as informações que coletamos para processar sua assinatura.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Informações agregadas
          </Typography>
          <Typography>
            Nós não vendemos suas informações pessoais. O Oiro pode agregar as
            informações que você e outras pessoas disponibilizam com relação aos
            Serviços e publicá-las ao público ou compartilhá- las com terceiros.
            Exemplos do tipo de informações que podemos agregar incluem
            informações sobre equipamento, uso, demografia, rotas e desempenho.
            O Oiro pode usar, vender, licenciar e compartilhar essas informações
            agregadas a terceiros para pesquisa, negócios e outros fins, como
            para aprimorar caminhadas, corridas ou atividades de ciclismo em
            cidades por meio do Oiro ou para ajudar nossos parceiros a entender
            mais sobre os atletas, inclusive as pessoas que usam seus produtos e
            serviços.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Como as informações são compartilhadas{' '}
          </Typography>
          <Typography>
            Além de compartilhar dados agregados sobre nossos usuários da forma
            descrita acima, também compartilhamos informações pessoais de acordo
            com suas preferências, à medida que for necessário para realizar
            nossos negócios e fornecer os Serviços, e quando necessário para
            fins jurídicos, conforme determinado abaixo.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Provedores de serviços{' '}
          </Typography>
          <Typography>
            Podemos compartilhar suas informações com terceiros que prestam
            serviços à Oiro, tais como suporte, aprimoramento, promoção e
            proteção dos Serviços, processamento de pagamentos ou atendimento de
            pedidos. Esses provedores de serviços somente têm acesso às
            informações necessárias para realizar essas funções limitadas em
            nosso nome e são obrigados a proteger e resguardar as suas
            informações. Também podemos usar provedores de serviços para coletar
            informações sobre seu uso dos Serviços ao longo do tempo em nosso
            nome, para possibilitar a promoção do Oiro ou a exibição de
            informações que possam ser relevantes a seus interesses nos Serviços
            ou em outros websites ou serviços.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Informações publicamente disponíveis{' '}
          </Typography>
          <Typography>
            Quando você entra para a comunidade Oiro, seu perfil e atividades
            são definidos como visualizáveis por todos por padrão. Seu nome e
            outras informações de perfil são visíveis por outros usuários do
            Oiro e o público e, conforme os seus controles de privacidade,
            informações e conteúdos adicionais que você compartilha também podem
            ser visíveis. Por exemplo, suas fotos e rotas podem ser acessadas
            por outros usuários e não usuários do Oiro ou visíveis em páginas do
            Oiro publicamente acessíveis ou em resultados de mecanismos de
            busca. Nós oferecemos uma variedade de ferramentas para controlar o
            compartilhamento das suas informações e incentivamos nossos usuários
            a ajustar seus controles de acordo com a experiência desejada.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Compartilhamento de informações e atividades
          </Typography>
          <Typography>
            Como uma rede social, compartilhamos suas informações com outros
            usuários do Oiro e o público, de acordo com os seus controles de
            privacidade. Você pode alterar a visibilidade das atividades que
            carrega no Oiro. Além disso, você pode nos contar sobre locais
            importantes, como o endereço da sua casa ou trabalho, habilitando
            uma Zona de privacidade nas suas configurações. Manteremos a
            privacidade de qualquer parte da sua atividade que comece ou termine
            na sua Zona de privacidade. Você pode optar por convidar os seus
            contatos a visualizar sua localização e atividades usando o Oiro.
            Você também pode compartilhar suas atividades com seus contatos por
            mensagem de po, e-mail ou em aplicativos de rede social como
            Facebook, Instagram e Twitter. Se você vincular sua conta do Oiro a
            outras redes sociais e compartilhar suas atividades, elas poderão
            ser visualizadas em tais plataformas de terceiros, inclusive suas
            informações de localização. Tenha cuidado ao compartilhar
            informações por meio de terceiros e leia atentamente as práticas de
            privacidade destes terceiros.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Negócios de terceiros por API ou outras integrações
          </Typography>
          <Typography>
            Nós permitimos que você compartilhe as suas informações e conteúdo
            com aplicativos, plugins ou websites de terceiros que se integrem
            com os Serviços, bem como com terceiros que trabalham com O Oiro
            para oferecer um recurso integrado, como um patrocinador de desafio
            ou relógio de monitoramento. Você pode escolher compartilhar seus
            dados de perfil e dados de atividade (incluindo atividades
            privadas). As informações coletadas por estes terceiros estão
            sujeitas aos seus termos e políticas. O Oiro não se responsabiliza
            por termos e políticas de terceiros.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Afiliadas e adquirentes de nossos negócios ou ativos{' '}
          </Typography>
          <Typography>
            Podemos compartilhar suas informações com afiliadas que estejam sob
            controle comum conosco e que devem cumprir os termos desta Política
            de privacidade com relação às suas informações. Se O Oiro estiver
            envolvida em uma combinação de empresas, oferta de valores
            mobiliários, falência, reorganização, dissolução ou outra transação
            semelhante, poderemos compartilhar ou transferir suas informações
            por conta de tal transação.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Requisitos legais{' '}
          </Typography>
          <Typography>
            Podemos preservar e compartilhar suas informações com terceiros,
            inclusive com órgãos de cumprimento da lei, agências públicas ou
            governamentais ou litigantes privados, dentro ou fora do seu país de
            residência, se determinarmos que a divulgação é permitida por lei ou
            necessária para o cumprimento da lei, incluindo responder a ordens
            judiciais, mandados, intimações ou outros processos legais ou
            regulatórios. Também podemos reter, preservar ou divulgar suas
            informações se determinarmos que isso seja necessário ou apropriado
            para evitar a morte ou lesão corporal grave de qualquer pessoa, para
            tratar de questões de segurança nacional ou outras questões de
            relevância pública, para evitar ou detectar violações de nossos
            Termos de serviço ou fraude ou abuso do Oiro ou de seus usuários, ou
            para proteger nossas operações ou nossa propriedade ou outros
            direitos legais, inclusive por meio de divulgação à nossa assessoria
            jurídica ou a outros consultores e terceiros com relação a litígios
            reais ou potenciais.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Como protegemos as informações{' '}
          </Typography>
          <Typography>
            Tomamos diversas medidas para proteger a coleta, transmissão e
            armazenamento dos dados que coletamos. Empregamos proteções
            razoáveis para suas informações que sejam apropriadas a sua
            confidencialidade. O Oiro contrata fornecedores que são líderes do
            setor em segurança online, incluindo verificação de Serviços, para
            fortalecer a segurança dos nossos Serviços. Os Serviços são
            registrados junto a autoridades de identificação de sites para que
            seu navegador possa confirmar a identidade do Oiro antes do envio de
            quaisquer informações pessoais. Além disso, os servidores seguros do
            Oiro protegem essas informações com tecnologia avançada de firewall.
          </Typography>
        </Box>
        <Typography style={{ fontWeight: 'bold' }}>
          GERENCIAMENTO DAS SUAS CONFIGURAÇÕES
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Controles de privacidade
          </Typography>
          <Typography>
            O Oiro oferece vários recursos e configurações para ajudar você a
            gerenciar sua privacidade e compartilhar suas atividades. A maioria
            dos controles de privacidade está localizada na página de controles
            de privacidade, mas algumas são específicas a determinadas
            atividades, atletas ou rotas. O Oiro oferece a você a opção de fazer
            com que suas atividades sejam privadas.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Ajuste de preferências de notificação e e-mail{' '}
          </Typography>
          <Typography>
            O Oiro oferece várias maneiras de gerenciar as notificações que você
            recebe. Você pode escolher interromper o recebimento de determinados
            e-mails e notificações indicando a sua preferência aqui. Você também
            pode cancelar a assinatura seguindo as instruções contidas no final
            de cada e-mail de marketing ou promocional. Os e-mails
            administrativos ou relacionados a serviços (para confirmar uma
            compra ou uma atualização nesta Política de privacidade ou em nossos
            Termos de serviço etc.) geralmente não oferecem a opção de cancelar
            o recebimento, visto que são necessários para fornecer os Serviços
            solicitados.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Atualização de informações da conta
          </Typography>
          <Typography>
            Você pode, a qualquer momento, corrigir, retificar ou atualizar
            informações de perfil ou conta ajustando tais informações em suas
            configurações da conta. Se precisar de assistência para corrigir
            informações imprecisas, entre em contato com O Oiro.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Exclusão de informações e contas e download dos seus dados
          </Typography>
          <Typography>
            Você pode excluir sua conta ou fazer o download dos seus dados.
          </Typography>
        </Box>
        <Typography>
          Depois que você fizer uma solicitação de exclusão, nós excluiremos, de
          forma permanente e irreversível, os seus dados pessoais de nossos
          sistemas, incluindo backups. Após a exclusão, os seus dados, inclusive
          a sua conta e atividades, não poderão ser restabelecidos. Após sua
          exclusão da conta, poderá levar até 90 dias para a exclusão das suas
          informações pessoais e registros dos nossos sistemas. Além disso, nós
          podemos reter informações nos casos em que solicitações de exclusão
          tenham sido feitas em cumprimento da lei e tomar outras medidas
          permitidas pela lei.
        </Typography>
        <Typography>
          Observe que o conteúdo compartilhado com outras pessoas, como fotos,
          ou que outras pessoas tenham copiado também poderá ficar visível após
          você ter excluído a sua conta ou excluído informações específicas do
          seu perfil. O seu perfil público poderá ser exibido em resultados de
          mecanismos de pesquisa até que o cache do mecanismo de pesquisa seja
          atualizado.
        </Typography>
        <Typography>
          O Oiro também oferece a opção de remover atividades individuais que
          você tiver publicado, sem excluir sua conta. Depois de removidas, as
          atividades podem permanecer nos sistemas do Oiro até que você exclua
          sua conta.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Seus direitos e nossas bases jurídicas{' '}
          </Typography>
          <Typography>
            Nós fornecemos o mesmo conjunto de ferramentas e controle de
            privacidade para todos os nossos usuários. O Oiro coleta, utiliza,
            compartilha e processa as informações que temos sobre você para os
            fins descritos nesta Política de privacidade, inclusive conforme
            necessário para fornecer os Serviços e cumprir nossas obrigações de
            acordo com os Termos de serviço. Por exemplo, nós não podemos
            fornecer os Serviços, exceto se coletarmos ou utilizarmos as suas
            informações de localização; onde você consentiu o processamento;
            onde necessário para cumprir uma obrigação legal, um mandado
            judicial ou para exercer ou defender reivindicações legais; para
            proteger seus interesses vitais, ou os de outras pessoas, como em
            caso de emergência; e onde necessário para os fins dos interesses
            legítimos do Oiro e de terceiros, como nossos interesses em proteger
            nossos usuários, os interesses de nossos parceiros em colaborar com
            nossos usuários e nossos interesses comerciais em garantir a
            sustentabilidade dos Serviços.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Retenção de informações{' '}
          </Typography>
          <Typography>
            Mantemos informações pelo tempo que for necessário para fornecer os
            Serviços para você e outras pessoas, sujeito a quaisquer obrigações
            jurídicas de reter tais informações por mais tempo. As informações
            relacionadas com a sua conta serão, em geral, mantidas até que não
            sejam mais necessárias para o fornecimento dos Serviços ou até a
            exclusão da sua conta. Além disso, você pode excluir algumas
            informações (por exemplo, informações de perfil) e remover
            atividades individuais da exibição nos Serviços sem excluir a sua
            conta. Por exemplo, se você remover o seu consentimento para O Oiro
            processar suas informações relacionadas à saúde, O Oiro excluirá
            todas as informações relacionadas à saúde que você carregou. Após
            sua exclusão da conta, poderá levar até 90 dias para a exclusão
            total das suas informações pessoais e registros dos nossos sistemas.
            Também podemos reter informações para cumprir a lei, prevenir
            fraude, cobrar taxas, resolver disputas, solucionar problemas,
            auxiliar em investigações, aplicar os Termos de serviço e tomar
            outras medidas permitidas por lei. As informações retidas serão
            tratadas de acordo com esta Política de privacidade.
          </Typography>
        </Box>
        <Typography>
          As informações associadas a você que não forem mais necessárias e
          relevantes para o fornecimento dos nossos Serviços serão
          descaracterizadas ou agregadas com outros dados não pessoais para
          fornecer informações que sejam comercialmente valiosas para O Oiro,
          como estatísticas de uso dos Serviços. Por exemplo, podemos reter
          segmentos ou rotas publicamente disponíveis e outras informações de
          geolocalização descaracterizadas para continuar aprimorando os
          Serviços e usamos informações agregadas nO Oiro. Essas informações
          serão desassociadas do seu nome e de outros identificadores.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Informações sobre a política de privacidade{' '}
          </Typography>
          <Typography>
            O Oiro reserva-se o direito de modificar esta Política de
            privacidade a qualquer momento. Revise-a periodicamente. Se O Oiro
            fizer alterações nesta Política de privacidade, a Política de
            privacidade atualizada será publicada nos Serviços em momento
            oportuno e, se fizermos alterações substanciais, forneceremos uma
            notificação proeminente. Se você não concordar com qualquer das
            alterações nesta Política de privacidade, deverá interromper o uso
            dos Serviços e excluir a sua conta.
          </Typography>
        </Box>
        <Typography style={{ fontWeight: 'bold' }}>
          POLÍTICA DE COOKIES
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Configurações das preferências de cookies
          </Typography>
          <Typography>
            Usamos Cookies não essenciais de terceiros para aprimorar sua
            experiência, fazer análises e apresentar anúncios relevantes.
            Selecione suas preferências de Cookies não essenciais de terceiros a
            seguir. Se “Aceitar”, usaremos Cookies não essenciais de terceiros.
            Se clicar em “Negar”, não usaremos Cookies não essenciais de
            terceiros. Você pode alterar esta preferência a qualquer momento.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            O que é um cookie?{' '}
          </Typography>
          <Typography>
            Um cookie é um elemento de dados que pode ser armazenado no
            navegador que você usa para acessar o Oiro, que habilita recursos e
            funcionalidades. Ele possibilita que nosso site “se lembre” das suas
            atividades ou preferências durante um período de tempo ou uma sessão
            específica. Se você fizer login no Oiro, os cookies nos permitirão
            mostrar as informações certas e personalizar sua experiência.
          </Typography>
        </Box>
        <Typography>
          Alguns dos cookies que utilizamos são definidos por nós (Oiro.com)
          para facilitar a prestação do serviço. Eles são conhecidos como
          Cookies primários. Também utilizamos Cookies de terceiros, que são
          definidos por outros sites (por exemplo, google.com) e aplicados em
          nosso website.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Como o Oiro usa cookies?{' '}
          </Typography>
          <Typography>
            Usamos cookies de sessão e cookies persistentes. Cookies de sessão
            são cookies temporários que não ficam armazenados no seu navegador
            depois da sessão em questão. Eles são usados para fins de
            autenticação e segurança e para garantir o funcionamento eficaz e
            necessário dos recursos do Oiro que você estiver utilizando. Também
            podemos usar um cookie de sessão para nos lembrarmos das suas
            preferências ao visualizar o site. Esses cookies de sessão são
            apagados quando você sai do navegador.
          </Typography>
        </Box>
        <Typography>
          Cookies persistentes são os cookies colocados em seu navegador por um
          período pré- determinado de tempo quando você visita o site. Eles
          expiram em uma data determinada ou quando são excluídos manualmente.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Cookies primários{' '}
          </Typography>
          <Typography>
            Desenvolvemos e usamos cookies por meio de nosso site para
            autenticar usuários, bloquear o uso mal-intencionado de credenciais
            de login e impedir acesso não autorizado a dados dos membros. Além
            disso, desenvolvemos e usamos cookies para coletar informações sobre
            o uso do site e do aplicativo, para entender e aprimorar o Oiro.
            Esses cookies nos ajudam também a saber como o site e o aplicativo
            estão funcionando em diferentes locais e a identificar quaisquer
            problemas na operação e no fornecimento de nossos produtos.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Cookies de terceiros{' '}
          </Typography>
          <Typography>
            Nós também permitimos a configuração de Cookies de terceiros em
            nosso site. Alguns desses cookies são necessários para a prestação
            dos serviços solicitados por você, enquanto outros não são
            necessários para a prestação dos serviços solicitados por você.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Cookies essenciais de terceiros{' '}
          </Typography>
          <Typography>
            Usamos Cookies essenciais de terceiros para que você possa navegar
            em nosso site e usar seus serviços e recursos. Por exemplo,
            permitimos que você se inscreva e faça login usando contas que você
            cria em produtos e serviços de terceiros, como Facebook, Google ou
            Apple (coletivamente, “Contas de terceiros”). Quando você se
            inscreve ou faz login usando Contas de terceiros, usamos Cookies
            essenciais de terceiros que são necessários para fazer sua
            autenticação.
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Cookies não essenciais de terceiros{' '}
          </Typography>
          <Typography>
            Usamos Cookies não essenciais de terceiros para nos auxiliarem a
            mensurar e entender como nossos produtos são usados e como podem ser
            otimizados. Podemos receber outras informações de análise desses
            terceiros.
          </Typography>
        </Box>
        <Typography>
          Alguns desses cookies são de publicidade, que empregamos para oferecer
          anúncios aos usuários que já visitaram nosso site.
        </Typography>
        <Typography>
          Outros cookies são usados para avaliar desempenho e para análise ou
          para realizar pesquisa comercial. Usamos os seguintes cookies para
          fins de desempenho, análise e pesquisa.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Gerenciamento e rejeição do uso de cookies{' '}
          </Typography>
          <Typography>
            Quando visitar nosso site pela primeira vez, você verá um banner
            perguntando se gostaria de aceitar o uso de cookies. Ao clicar em
            “Aceito,” você concorda com o uso de todos os cookies conforme
            descritos aqui, inclusive Cookies não essenciais de terceiros.
          </Typography>
        </Box>
        <Typography>
          Só usaremos Cookies não essenciais de terceiros se você clicar em
          &quot;Aceito&quot; no banner de cookies ou nas suas preferências de
          cookies. Você pode gerenciar suas preferências de cookies a qualquer
          momento ativando o botão de opção na parte superior desta política. Se
          você clicar em &quot;negar&quot; nas suas preferências de cookies ou
          no banner de cookies, nós não usaremos Cookies de não essenciais
          terceiros.
        </Typography>
        <Typography>
          A maioria dos navegadores permite escolher se você deseja ou não
          aceitar cookies. Navegadores diferentes disponibilizam controles
          diferentes para você. Por isso, fornecemos os links abaixo com
          instruções sobre como fazer isso para os navegadores mais populares.
          Geralmente, o seu navegador oferecerá a opção de aceitar, recusar ou
          excluir todos os cookies, ou os cookies de terceiros em um site, ou os
          cookies de sites específicos. Entretanto, se você restringir nossa
          capacidade de definir cookies, poderá limitar a funcionalidade total
          do Oiro, o que deixará alguns dos nossos serviços e recursos
          indisponíveis para você.
        </Typography>
        <Typography>
          Em alguns casos, você pode gerenciar ou recusar cookies de terceiros
          analisando cookie por cookie.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Alterações nesta política{' '}
          </Typography>
          <Typography>
            Esta política pode ser alterada de tempos em tempos, então
            consulte-a com frequência para ter as informações mais atualizadas
            sobre cookies. Notificaremos você sobre quaisquer alterações
            atualizando esta página.
          </Typography>
        </Box>
        <Typography style={{ fontWeight: 'bold' }}>
          SEUS DIREITOS E NOSSAS BASES JURÍDICAS{' '}
        </Typography>
        <Typography>
          O Oiro tem o compromisso de fornecer a você informações significativas
          e opções sobre as informações que compartilha conosco. Explicamos como
          coletamos, usamos, compartilhamos e protegemos suas informações em
          nossa Política de privacidade. Fornecemos o mesmo conjunto de
          ferramentas e controles de privacidade a todos os nossos membros em
          todo o mundo que usam os sites do Oiro e os aplicativos e serviços
          móveis relacionados (coletivamente, os “Serviços”).
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Direitos de usuários
          </Typography>
          <Typography>
            A Autoridade Brasileira de Proteção de Dados (&quot;DPA&quot;) será
            estabelecida e fornecerá diretrizes sobre como interpretar e
            implementar os requisitos da LGPD. Como essas diretrizes ainda não
            foram estabelecidas, nossa abordagem está sujeita a alterações.
          </Typography>
        </Box>
        <Typography>
          Se você estiver habitualmente localizado no Brasil, terá direitos sob
          a Lei Geral de Proteção de Dados (&quot;LGPD&quot;), incluindo o
          direito de acessar, retificar, fazer download ou apagar as suas
          informações, bem como o direito de restringir e opor-se a determinados
          processamentos das suas informações.
        </Typography>
        <Typography>
          A LGPD exige que tenhamos bases jurídicas para coletar, usar,
          compartilhar e processar informações sobre você.
        </Typography>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Contrato da API Oiro{' '}
          </Typography>
          <Typography>
            O Contrato da API Oiro (“Contrato”) é realizado e celebrado entre O
            Oiro e você. “Você” significa você individualmente ou a pessoa
            jurídica que você representa. Se você está celebrando este Contrato
            para uma pessoa jurídica, você declara e nos garante que tem
            autoridade legal para vincular essa pessoa jurídica a este Contrato.
            Aqui estão alguns destaques:
          </Typography>
        </Box>
        <Typography>
          Esperamos que você crie um aplicativo que seja útil, inspirador e
          ajude a construir uma comunidade para os atletas do Oiro.
        </Typography>
        <Typography>
          Você não pode criar um aplicativo que compita ou meramente replique o
          Oiro.
        </Typography>
        <Typography>
          Você é responsável por seu uso da API Oiro e do seu aplicativo.
        </Typography>
        <Typography>
          Seu uso da API Oiro está sujeito a limites de volume e outras
          restrições de uso.
        </Typography>
        <Typography>
          Você deve sempre respeitar os usuários do Oiro e suas opções de
          privacidade. Isso inclui não compartilhar os dados dos usuários do
          Oiro com outros usuários ou terceiros.
        </Typography>
        <Typography>
          Você concorda em usar medidas de segurança apropriadas para proteger
          quaisquer dados que obtiver do Oiro.
        </Typography>
        <Typography>
          O uso da API Oiro é um privilégio concedido a você pelO Oiro, não um
          direito. O Oiro pode modificar ou remover o acesso à API Oiro a
          qualquer momento.
        </Typography>
        <Typography>
          O Oiro não oferece garantias nem é responsável pelo seu uso da API
          Oiro.
        </Typography>
        <Typography>
          O Oiro pode coletar e usar dados de seu acesso à API Oiro.
        </Typography>
        <Typography>
          O Oiro pode destacar ou promover seu aplicativo, mas não tem a
          obrigação de fazê-lo.
        </Typography>
        <Typography>
          Você deve cumprir as Diretrizes de marca de API Oiro e usar os
          logotipos e links fornecidos pelo Oiro quando utilizar dados do Oiro
          em seu aplicativo.
        </Typography>
        <Typography>
          Seu aplicativo deve cumprir o Contrato da API Oiro, os Termos de
          serviço do Oiro, sua política de privacidade e todas as leis cabíveis.
          O Oiro pode realizar uma auditoria para garantir a conformidade
          contínua de seu aplicativo.
        </Typography>
      </Box>
      <Footer />
    </Box>
  );
};

export default PrivacyAndPolicy;
