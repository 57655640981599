import React from 'react';
import Button from '@material-ui/core/Button';
import { FaFacebookF } from 'react-icons/fa';
import ReactDOM from 'react-dom';

class ButtonFb extends React.Component {
  render() {
    return (
      <Button
        fullWidth
        variant="contained"
        style={{
          marginTop: 15,
          color: '#fff',
          backgroundColor: '#1773EA',
          borderRadius: 50,
        }}
        type="button"
        startIcon={<FaFacebookF style={{ marginRight: 9 }} />}
        onClick={this.props.onClick}
      >
        Logar com o Facebook
      </Button>
    );
  }
}

ReactDOM.render(<ButtonFb />, document.getElementById('root'));

export default ButtonFb;
