import { makeStyles } from '@material-ui/core';
import banner from 'images/logo-banner.png';

export const useStyles = makeStyles(theme => ({
  eventImageDisplay: {
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${banner})`,
    backgroundPosition: 'center',
    backgroundColor: theme.palette.primary.main,
    backgroundSize: 'auto',
    borderRadius: 16,
    boxShadow: '0 8px 24px #5637771A',
    border: '1px solid #d6d6d6',
    '& img': {
      position: 'absolute',
      zIndex: 3,
      right: 6,
      bottom: 6,
      transition: '0.3s ease',
      cursor: 'pointer',
      borderRadius: 6,
      '&:hover': {
        filter: 'brightness(94%)',
      },
    },
  },
}));
