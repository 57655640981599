import { Box, Button, Typography } from '@material-ui/core';
import {
  BathtubOutlined,
  DirectionsBikeOutlined,
  DirectionsCarOutlined,
  RestaurantOutlined,
  Settings,
} from '@material-ui/icons';
import React from 'react';
import { FaBriefcaseMedical } from 'react-icons/fa';
import {
  ALIMENTACAO,
  APOIO_MECANICO,
  APOIO_MEDICO,
  BANHEIRO,
  BATEDOR,
  CARRO_APOIO,
} from 'utils/constants';
import { useStyles as useEventTypeStyles } from '../EventExerciseType/styles';
import { useStyles } from './styles';

const EventStructure = ({
  values = [],
  onChange = () => {},
  small = false,
  disabled = false,
}) => {
  const eventTypeClasses = useEventTypeStyles();
  const classes = useStyles();

  const handleChange = value => {
    if (values.includes(value)) {
      return onChange(values.filter(item => item !== value));
    }

    onChange([...values, value]);
  };

  return (
    <Box className={eventTypeClasses.container}>
      <Typography variant="h6" color="primary">
        Estrutura/Apoio
      </Typography>
      <Box
        className={
          !small ? classes.buttonsContainer : classes.smallButtonsContainer
        }
      >
        <Box>
          <Button
            data-cy="banheiro"
            onClick={() => handleChange(BANHEIRO)}
            variant={values.includes(BANHEIRO) ? 'contained' : 'outlined'}
            disabled={disabled}
            color="primary"
          >
            <BathtubOutlined />
          </Button>
          <Typography>Banheiro</Typography>
        </Box>
        <Box>
          <Button
            data-cy="apoio-medico"
            onClick={() => handleChange(APOIO_MEDICO)}
            variant={values.includes(APOIO_MEDICO) ? 'contained' : 'outlined'}
            disabled={disabled}
            color="primary"
          >
            <FaBriefcaseMedical size={25} />
          </Button>
          <Typography>
            Apoio <br /> médico
          </Typography>
        </Box>
        <Box>
          <Button
            data-cy="carro-de-apoio"
            onClick={() => handleChange(CARRO_APOIO)}
            variant={values.includes(CARRO_APOIO) ? 'contained' : 'outlined'}
            disabled={disabled}
            color="primary"
          >
            <DirectionsCarOutlined size={30} />
          </Button>
          <Typography>
            Carro
            <br /> de apoio
          </Typography>
        </Box>
        <Box>
          <Button
            data-cy="batedor"
            onClick={() => handleChange(BATEDOR)}
            variant={values.includes(BATEDOR) ? 'contained' : 'outlined'}
            disabled={disabled}
            color="primary"
          >
            <DirectionsBikeOutlined size={30} />
          </Button>
          <Typography>Batedor</Typography>
        </Box>
        <Box>
          <Button
            data-cy="alimentacao"
            onClick={() => handleChange(APOIO_MECANICO)}
            variant={values.includes(APOIO_MECANICO) ? 'contained' : 'outlined'}
            disabled={disabled}
            color="primary"
          >
            <Settings size={30} />
          </Button>
          <Typography>Apoio mecânico</Typography>
        </Box>
        <Box>
          <Button
            data-cy="alimentacao"
            onClick={() => handleChange(ALIMENTACAO)}
            variant={values.includes(ALIMENTACAO) ? 'contained' : 'outlined'}
            disabled={disabled}
            color="primary"
          >
            <RestaurantOutlined size={30} />
          </Button>
          <Typography>Alimentação</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EventStructure;
