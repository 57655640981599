import { makeStyles } from '@material-ui/core';
import defaultEventImage from 'images/logo-banner.png';

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#f5f5f5',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  eventsContainer: {
    marginTop: '3%',
    width: 800,
    minHeight: 500,
    margin: 'auto',
    padding: 25,
    position: 'relative',
    justifyContent: 'center',
    zIndex: 2,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
  },
  eventCard: {
    ...theme.overrides.MuiCard.root,
    margin: 10,
    maxWidth: 240,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  eventDetails: {
    '& .MuiTypography-root': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
    },
    '& .MuiTypography-root:not(h6)': {
      fontSize: 11,
      color: 'gray',
      fontWeight: 600,
      margin: '0 10px',
    },
    '& h6': {
      fontSize: 14,
      marginTop: 10,
      color: theme.palette.primary.main,
      marginLeft: 10,
    },
    background: '#f9f9f9',
    cursor: 'pointer',
    paddingBottom: 10,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    transition: '0.3s',
    '&:hover': {
      filter: 'brightness(0.97)',
    },
  },
  eventImage: {
    height: 70,
    width: '100%',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    backgroundImage: `url(${defaultEventImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  eventActions: {
    '& .MuiButton-root': {
      fontSize: 14,
      padding: 0,
      height: 35,
      borderTopLeftRadius: 0,
      borderBottom: 'none',
      boxShadow: 'none',
      color: theme.palette.primary.main,
      '&:disabled': {
        backgroundColor: '#eee',
        color: '#ccc',
        cursor: 'not-allowed',
      },
    },
    '& .MuiIconButton-root': {
      fontSize: 10,
      color: theme.palette.primary.main,
      padding: 0,
      height: 35,
      width: 'fit-content',
      border: '1px solid #ccc',
      boxShadow: 'none',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottom: 'none',
    },
  },
}));
