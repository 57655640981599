import React from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

const NumberInput = ({
  input: { onChange, ...inputProps },
  meta,
  handleChange = value => value,
  numberFormat,
  hideErrorTexts = false,
  ...rest
}) => {
  const showError = !!(meta.touched && meta.error);

  return (
    <NumberFormat
      {...inputProps}
      customInput={TextField}
      helperText={
        hideErrorTexts ? undefined : showError ? meta.error : undefined
      }
      error={showError}
      thousandSeparator="."
      decimalSeparator=","
      format={numberFormat}
      allowNegative={false}
      decimalScale={0}
      fixedDecimalScale
      onValueChange={({ floatValue }) => {
        onChange(floatValue);
        handleChange(floatValue);
      }}
      {...rest}
    />
  );
};

export default NumberInput;
