import React, { useEffect, useMemo, useState } from 'react';

import EventOverall from 'forms/CreateEventForm/EventOverall';
import Header from 'components/Header/Header';
import { Box } from '@material-ui/core';
import Footer from 'components/Footer';
import { useHistory, useParams } from 'react-router-dom';
import api from 'services/api';
import ConfirmDialog from 'dialogs/ConfirmDialog';
import {
  ADMIN_PROFILE,
  DEFAULT_STRUCTURE_ARRAY,
  EVENTO_ONLINE,
  STATUS_EVENTO_COMPLETO,
  STATUS_EVENTO_INCOMPLETO,
} from 'utils/constants';
import { toast } from 'react-toastify';
import { parseISO } from 'date-fns';
import { useAuth } from 'contexts/auth';

const EventoDetalhe = () => {
  const { id } = useParams();
  const { user, profiles } = useAuth();
  const history = useHistory();
  const isAdmin = profiles?.includes(ADMIN_PROFILE);

  const [initialValues, setInitialValues] = useState({});
  const [openConclude, setOpenConclude] = useState(false);
  const [routes, setRoutes] = useState([]);

  const isPublicPage = isNaN(Number(id));
  const isOwnEvent = useMemo(
    () => isAdmin || initialValues?.user_id === user?.id,
    [initialValues],
  );

  const handleUpdateEvent = async values => {
    try {
      let imagem_id = null;

      if (values.imagemEvento) {
        if (!values.imagemEvento.size) {
          imagem_id = undefined;
        } else {
          const imageData = new FormData();
          imageData.append('file', values.imagemEvento);

          imagem_id = (await api.post('file', imageData)).data.data.id;
        }
      }

      const isOnline = Number(values.modality) === EVENTO_ONLINE;

      const estrutura_de_apoio = DEFAULT_STRUCTURE_ARRAY.reduce((acc, curr) => {
        return { ...acc, [curr.prop]: values.structure.includes(curr.id) };
      }, {});

      const eventoData = {
        nome: values.name,
        visibilidade: Number(values.visibility),
        modalidade: Number(values.tipo_evento),
        objetivo_tempo: isOnline ? values.horas * 60 + values.minutos : null,
        objetivo_distancia: isOnline
          ? values.distancia_kilometros * 1000 + values.distancia_metros
          : null,
        objetivo_altimetria: isOnline ? values.altimetria : null,
        tipo_evento: Number(values.modality),
        status_evento: STATUS_EVENTO_INCOMPLETO,
        descricao: values.description,
        regras: values.rules,
        tipo_atividade: Number(values.tipo_atividade),
        tipos_bike:
          values.tipo_bike !== String(null) ? Number(values.tipo_bike) : null,
        imagem_id,
        estrutura_de_apoio,
        data_fim: parseISO(values.prazo),
      };

      const {
        data: {
          data: { id: evento_id },
        },
      } = await api.put(`eventos/${id}`, eventoData);

      for (const trajeto of values.routes) {
        const trajetoData = {
          evento_id,
          nome: trajeto.nome,
          partida_ponto: trajeto.partida_ponto,
          partida_ponto_endereco: trajeto.partida_ponto_endereco,
          chegada_ponto: trajeto.chegada_ponto,
          chegada_ponto_endereco: trajeto.chegada_ponto_endereco,
          data_partida: trajeto.data_partida,
          data_chegada: trajeto.data_chegada,
          pontos_trajeto: trajeto.pontos_trajeto,
          distancia: trajeto.distancia,
        };

        await api.put(`eventos/trajetos/${trajeto.trajeto_id}`, trajetoData);
      }

      toast.success('Evento editado com sucesso');
      history.push('/');
    } catch (err) {
      console.log(err);
    }
  };

  const getEvento = async () => {
    const params = { include: 'imagemEvento,trajetos' };

    let data;

    if (isPublicPage) {
      data = (await api.get(`private/evento/${id}`)).data;
    } else {
      data = (await api.get(`eventos/${id}`, { params })).data;
    }

    const trajetos = data.trajetos.map(trajeto => ({
      ...trajeto,
      trajeto_id: trajeto.id,
      directionsWaypoints: trajeto.pontos.map(ponto => ponto.ponto),
      pontos_trajeto: trajeto.pontos.map(ponto => ponto.ponto),
    }));

    const evento = {
      exerciseType: data.tipo_atividade,
      modality: data.tipo_evento,
      photoAndName: {
        imagemEvento: data.imagemEvento,
        nome: data.nome,
      },
      descriptionAndRules: {
        description: data.descricao,
        rules: data.regras,
      },
      bikeType: data.tipos_bike,
      privacy: data.visibilidade,
      type: data.modalidade,
      objective: {
        horas: Math.floor(data.objetivo_tempo / 60),
        minutos: data.objetivo_tempo % 60,
        distancia_kilometros: Math.floor(data.objetivo_distancia / 1000),
        distancia_metros: data.objetivo_distancia % 1000,
        altimetria: data.objetivo_altimetria,
        prazo: data.data_fim,
      },
      structure: DEFAULT_STRUCTURE_ARRAY.map(struct => {
        return data.estrutura_de_apoio?.[struct.prop] ? struct.id : undefined;
      }),
      user_id: data.user_id,
    };

    setRoutes(trajetos);
    setInitialValues(evento);
  };

  const handleConcludeEvent = async () => {
    await api.put(`eventos/${id}`, { status_evento: STATUS_EVENTO_COMPLETO });

    toast.success('Evento concluído com sucesso!');

    history.push('/');
  };

  const onDeleteTrajeto = async trajetoId => {
    try {
      await api.delete(`eventos/trajetos/${trajetoId}`);

      getEvento();
      toast.success('Trajeto removido com sucesso!');
    } catch (err) {
      toast.error('Houve um erro ao remover o trajeto.');
    }
  };

  useEffect(() => {
    getEvento();
  }, []);

  return (
    <>
      <Header isPublicPage={isPublicPage} />
      <Box>
        <EventOverall
          onlyRead={!isOwnEvent}
          onSubmitEvent={handleUpdateEvent}
          onConcludeEvent={() => setOpenConclude(true)}
          onDeleteTrajeto={onDeleteTrajeto}
          isEditing={isOwnEvent}
          initialValues={{ ...initialValues, routes }}
          goBack={() => history.push('/')}
        />

        <Footer additionalStyles={{ marginTop: -20 }} />

        <ConfirmDialog
          title="Concluir evento?"
          content="Todos os participantes deste evento serão notificados."
          open={openConclude}
          onClose={() => setOpenConclude(false)}
          actions={[
            {
              label: 'Cancelar',
              buttonColor: 'primary',
              buttonVariant: 'outlined',
              action: () => {},
            },
            {
              label: 'Concluir',
              buttonColor: 'secondary',
              buttonVariant: 'contained',
              action: handleConcludeEvent,
            },
          ]}
        />
      </Box>
    </>
  );
};

export default EventoDetalhe;
