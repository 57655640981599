import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: 10,
  },
  radioGroup: {
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
      fontSize: 15,
      marginBottom: 0,
    },
    '& .Mui-disabled': {
      color: theme.palette.grey[500],
    },
    '& span.Mui-disabled.Mui-checked': {
      '& span': {
        backgroundColor: theme.palette.grey[500],
        borderRadius: 6,
        border: 'none',
      },
    },
  },
  icon: {
    width: 20,
    height: 20,
    border: '1px solid #ababab',
    transition: 'all 0.3s',
    borderRadius: 6,
  },
  checkedIcon: {
    width: 20,
    height: 20,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 7,
    backgroundColor: theme.palette.primary.main,
  },
}));
