import { setupYup } from 'config/yup';

const Yup = setupYup();

export const schema = Yup.object().shape({
  password: Yup.string().required(),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas não batem')
    .required(),
});
