import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    height: 174,
    paddingTop: 20,
  },
  container: {
    maxWidth: 800,
    margin: 'auto',
    marginTop: 20,
  },
  layout: { maxWidth: 800, margin: 'auto' },
  imageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pageName: {
    color: 'white',
    fontWeight: 600,
    marginTop: 15,
  },
  greetingText: {
    color: 'white',
    fontWeight: 600,
  },
  navBar: {
    marginTop: 20,
    marginBottom: -25,
  },
  buttonGroup: {
    width: '100%',
    '& .MuiButton-root': {
      fontWeight: 700,
      backgroundColor: '#f9f9f9',
      width: '100%',
      color: theme.palette.primary.main,
      border: 'none',
      transition: '0.3s',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: 'white',
        filter: 'brightness(0.9)',
      },
    },
  },
  searchInput: {
    '& .MuiIconButton-root': {
      boxShadow: 'none',
    },
  },
  logoutButton: {
    background: '#f9f9f9',
    transition: '0.3s',
    color: '#e33',
    '&:hover': {
      backgroundColor: '#f9f9f9',
      filter: 'brightness(0.9)',
    },
  },
}));
