import React, { useCallback, useEffect, useState } from 'react';

import { Dialog, DialogContent, Button, Grid } from '@material-ui/core';
import EventRouteMap from 'forms/CreateEventForm/EventRouteMap';
import EventRoute from 'forms/CreateEventForm/EventRoute';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { EVENT_ROUTE_FORM, EVENT_ROUTE_MAP_FORM } from 'utils/constants';
import { If, Then, Else } from 'react-if';

const TrajetoDialog = ({
  onClose = () => {},
  open = false,
  onEdit = () => {},
  currentRoute = {},
  index,
}) => {
  const [route, setRoute] = useState();
  const [currentForm, setCurrentForm] = useState(EVENT_ROUTE_MAP_FORM);

  const handleClose = useCallback(() => {
    onClose();
    setCurrentForm(EVENT_ROUTE_MAP_FORM);
  }, []);

  useEffect(() => {
    if (route) return;

    setRoute(currentRoute);
  }, [currentRoute]);

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogContent>
        <If condition={currentForm === EVENT_ROUTE_MAP_FORM}>
          <Then>
            <EventRouteMap
              currentRoute={route || currentRoute}
              index={index}
              currentRouteId={currentRoute.trajeto_id || currentRoute.id}
              editing
              onChange={setRoute}
            />
          </Then>
          <Else>
            <EventRoute value={route || currentRoute} onChange={setRoute} />
          </Else>
        </If>

        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <If condition={currentForm === EVENT_ROUTE_FORM}>
              <Then>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<ArrowBack />}
                  onClick={() => {
                    setCurrentForm(EVENT_ROUTE_MAP_FORM);
                  }}
                >
                  Voltar
                </Button>
              </Then>
              <Else>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    handleClose();
                    setRoute(undefined);
                  }}
                >
                  Cancelar
                </Button>
              </Else>
            </If>
          </Grid>
          <Grid item>
            <If condition={currentForm === EVENT_ROUTE_MAP_FORM}>
              <Then>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!route}
                  onClick={() => {
                    setCurrentForm(EVENT_ROUTE_FORM);
                  }}
                  endIcon={<ArrowForward />}
                >
                  Próximo
                </Button>
              </Then>
              <Else>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!route}
                  onClick={() => {
                    onEdit(route);
                    handleClose();
                  }}
                >
                  Adicionar trajeto
                </Button>
              </Else>
            </If>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TrajetoDialog;
