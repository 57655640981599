import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MUIDataTableSearch: {
      searchText: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    MUIDataTableHeadCell: {
      sortAction: {
        alignItems: 'center',
      },
      toolButton: {
        boxShadow: 'none',
      },
    },
    MUIDataTable: {
      paper: {
        boxShadow: 'none',
        backgroundColor: '#f9f9f9',
      },
    },
    MUIDataTableToolbar: {
      root: {
        paddingLeft: '10px',
      },
      icon: {
        boxShadow: 'none',
      },
      titleRoot: {
        color: '#563777',
        margin: 0,
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: '#f9f9f9 !important',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '16px',
        boxShadow: '0 8px 24px #5637771A',
        fontWeight: 'bold',
        height: 50,
        textTransform: 'none',
        fontSize: 16,
      },
      contained: {
        borderRadius: '16px',
        boxShadow: '0 8px 24px #5637771A',
      },
    },
    MuiButtonGroup: {
      root: {
        boxShadow: '0 8px 24px #8158A415',
        borderRadius: '16px',
      },
    },
    MuiIconButton: {
      root: {
        boxShadow: '0 8px 24px #8158A415',
        borderRadius: '16px',
      },
    },
    MuiContainer: {
      root: {
        backgroundColor: '#f5f5f5',
      },
    },
    MuiCard: {
      root: {
        backgroundColor: '#f9f9f9',
        borderRadius: '24px',
        boxShadow: '0 8px 34px #56377726',
        border: '1px solid #d6d6d6',
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: '#f5f5f5',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '16px',
        boxShadow: '0 8px 24px #8158A415',
        backgroundColor: '#f9f9f9',
        height: 50,
      },
    },
    MuiRadio: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 24,
        paddingBottom: 10,
      },
      root: {},
    },
    MuiDialogTitle: {
      root: {
        color: '#563777',
      },
    },
  },
  palette: {
    primary: {
      main: '#563777',
      dark: '#4a2d69', // hover
      contrastText: '#fff',
    },
    secondary: {
      main: '#3CE162',
      dark: '#2fb54e',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: 'bold',
    },
  },
});

export default theme;
