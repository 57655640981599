import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    height: 900,
    flexDirection: 'column',
  },
  header: {
    backgroundColor: '#563777',
    height: 452,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '13%',
  },
  logo: {
    width: 122,
    marginBottom: 170,
  },
  middleSection: {
    height: '100%',
  },
  cardsWrapper: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    top: '15%',
    overflowX: 'hidden',
    justifyContent: 'center',
    paddingBottom: 40,
  },
  loginCard: {
    height: '60%',
    padding: 70,
    marginTop: '8%',
    paddingRight: 150,
    width: 644,
  },
  codeCard: {
    marginLeft: -170,
    display: 'flex',
    padding: 70,
    flexDirection: 'column',
    alignItems: 'center',
    width: 483,
    '& .MuiBox-root': {
      border: '1px solid #ddd',
    },
  },
  codeInput: {
    marginTop: 20,
    marginBottom: 20,
  },
  instructionsText: {
    marginTop: 20,
    fontWeight: 600,
  },
});
