import React, { useState } from 'react';
import DialogForm from 'components/DialogForm';
import Button from '@material-ui/core/Button';
import { Chip, Grid, Typography } from '@material-ui/core';
import formatStringByPattern from 'format-string-by-pattern';
import {
  STATUS_APPROVAL_COLOR,
  STATUS_APPROVAL_NAME,
  STATUS_APROVADO,
  STATUS_REPROVADO,
} from 'utils/constants';
import { getFormattedDate } from 'utils/transform';
import api from 'services/api';
import { useStyles } from './styles';
import fileIcon from 'images/file.png';
import expandContentIcon from 'images/expand-content.png';
import ApprovalStatusModal from './ApprovalStatusModal';

function ApproveUserDialog({
  initialValues,
  onClose = () => {},
  open = false,
}) {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState('');

  const handleApproveUser = async status => {
    await api.put('approve', {
      user_id: initialValues.id,
      status_approval: status,
    });

    setApprovalStatus(status);
    setOpenModal(true);
    onClose();
  };

  const handleOpenImage = async photo => {
    window.open(
      `${process.env.REACT_APP_API_URL}/files_s3/${photo.imagem_id}`,
      '_blank',
    );
  };

  return (
    <div>
      <DialogForm title="Aprovação" open={open} onClose={onClose} maxWidth="md">
        {() => (
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Chip
                label={STATUS_APPROVAL_NAME[initialValues.status_approval]}
                style={{
                  backgroundColor:
                    STATUS_APPROVAL_COLOR[initialValues.status_approval],
                }}
              />
            </Grid>

            <Grid item xs={4} style={{ marginLeft: 4 }}>
              <Typography
                color="primary"
                style={{ fontWeight: 'normal', fontSize: 10 }}
              >
                Nome*
              </Typography>
              <Typography style={{ fontSize: 16 }}>
                {initialValues?.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                color="primary"
                style={{ fontWeight: 'normal', fontSize: 10 }}
              >
                CPF*
              </Typography>
              <Typography style={{ fontSize: 16 }}>
                {formatStringByPattern('XXX.XXX.XXX-XX', initialValues?.cpf)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                color="primary"
                style={{ fontWeight: 'normal', fontSize: 10 }}
              >
                Data de nascimento*
              </Typography>
              <Typography style={{ fontSize: 16 }}>
                {getFormattedDate(initialValues?.data_nascimento, 'P')}
              </Typography>
            </Grid>

            <Grid item xs={9} style={{ marginTop: 10 }}>
              <Typography color="primary" style={{ fontWeight: 'bold' }}>
                Documentos
              </Typography>
            </Grid>

            {initialValues.documents &&
              initialValues.documents.map((photo, index) => (
                <Grid key={index} className={classes.FileBoxContainer}>
                  <Grid
                    className={classes.FileBoxTextContainer}
                    onClick={() => handleOpenImage(photo)}
                  >
                    <img
                      src={fileIcon}
                      color="gray"
                      style={{
                        width: '18px',
                      }}
                    />
                    <Typography>imagem00{index + 1}.jpg</Typography>
                    <img
                      src={expandContentIcon}
                      color="black"
                      style={{
                        width: '14px',
                        height: '14px',
                        marginTop: '4px',
                      }}
                    />
                  </Grid>
                  <Grid className={classes.Rectangle} />
                </Grid>
              ))}

            <Grid
              container
              xs={12}
              justifyContent="flex-end"
              style={{ padding: 5, marginTop: 20 }}
            >
              <Button
                onClick={() => handleApproveUser(STATUS_REPROVADO)}
                style={{
                  color: '#F07070',
                  width: '20%',
                }}
              >
                Reprovar
              </Button>
              <Button
                onClick={() => handleApproveUser(STATUS_APROVADO)}
                variant="contained"
                color="primary"
                style={{
                  width: '20%',
                }}
              >
                Aprovar
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogForm>
      <ApprovalStatusModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        status={approvalStatus}
      />
    </div>
  );
}

export default ApproveUserDialog;
