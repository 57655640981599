import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/pt-BR';
import numeral from 'numeral';
import 'numeral/locales/pt-br';

numeral.locale('pt-br');

export function getFormattedDate(date, code = 'P') {
  if (!date) return '';
  return format(parseISO(date), code, { locale });
}

export function getFormattedNumber(number, format = '0,0[.]00') {
  if (isNaN(number)) return '';
  return numeral(number || 0).format(format);
}

export function getMoney(number) {
  if (isNaN(number)) return '';
  return numeral(number || 0).format('$ 0,0[.]00');
}

export function getPercentage(number) {
  if (isNaN(number)) return '';
  return numeral((number || 0) / 100).format('0[.]00 %');
}

export function formatMinutes(value) {
  if (Number(value) === 0) return value;

  if (Number(value) < 59) return String(getFormattedNumber(value, '00'));

  return '59';
}

export function calculateDistance(legs) {
  const distances = legs?.map(leg => leg.distance.value);
  return distances?.reduce((acc, curr) => acc + curr);
}

export const convertDirectionsToTrajeto = ({
  googleDirections,
  currentRouteId,
  routeRest = {},
}) => {
  const nome = `Trajeto ${currentRouteId}`;

  const origin = googleDirections.request.origin;
  const destination = googleDirections.request.destination;
  const partida_ponto = {
    lat: origin.location?.lat() || origin.lat(),
    lng: origin.location?.lng() || origin.lng(),
  };
  const chegada_ponto = {
    lat: destination.location?.lat() || destination.lat(),
    lng: destination.location?.lng() || destination.lng(),
  };

  const legs = googleDirections.routes[0].legs;
  const partida_ponto_endereco = legs[0].start_address;
  const chegada_ponto_endereco = legs[legs.length - 1].end_address;

  const points = googleDirections.routes[0].overview_path?.map(point => ({
    lat: point.lat(),
    lng: point.lng(),
  }));

  const distance = calculateDistance(legs);

  const directionsWaypoints = googleDirections.request.waypoints?.map(
    point => ({
      lat: point.location?.location?.lat() || point.location?.lat(),
      lng: point.location?.location?.lng() || point.location?.lng(),
    }),
  );

  const pontos_trajeto = [partida_ponto, ...points, chegada_ponto];

  console.log({ routeRest, currentRouteId });

  return {
    ...routeRest,
    id: currentRouteId,
    nome,
    partida_ponto,
    chegada_ponto,
    partida_ponto_endereco,
    chegada_ponto_endereco,
    directionsWaypoints,
    pontos_trajeto,
    distancia: distance,
  };
};

export const getDirecitons = ({
  origin,
  destination,
  waypoints,
  editing,
  travelMode,
}) => {
  if (!google) return;

  const directionsService = new google.maps.DirectionsService();

  return new Promise(resolve => {
    directionsService.route(
      {
        origin: new google.maps.LatLng(origin.lat, origin.lng),
        destination: new google.maps.LatLng(destination.lat, destination.lng),
        waypoints: !editing ? waypoints : waypoints.slice(1, -1),
        travelMode,
      },
      (result, status) => {
        resolve({ result, status });
      },
    );
  });
};
