import React from 'react';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { TextField } from 'final-form-material-ui';
import Button from '@material-ui/core/Button';
import ButtonFb from 'components/ButtonFb';

import { validate } from 'utils/validate';
import { schema } from './schema';
import { Box, InputAdornment, useTheme } from '@material-ui/core';
import { Mail, Key } from 'icons';
import { FaFacebookF } from 'react-icons/fa';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const focusOnErrors = createDecorator();

function LoginForm({ onSubmit }) {
  const theme = useTheme();

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      validate={validate(schema)}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Field
            name="email"
            type="text"
            component={TextField}
            placeholder="E-mail"
            margin="normal"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Mail />
                </InputAdornment>
              ),
            }}
          />
          <Field
            name="password"
            type="password"
            component={TextField}
            placeholder="Password"
            margin="normal"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={{ color: theme.palette.primary.main }}
                  position="start"
                >
                  <Key size={20} />
                </InputAdornment>
              ),
            }}
          />

          <Box mt={1}>
            <Button
              disabled={submitting}
              fullWidth
              variant="contained"
              style={{ borderRadius: 50 }}
              color="primary"
              type="submit"
              data-cy="enviar"
            >
              Enviar
            </Button>
            <FacebookLogin
              appId="219962227437144"
              callback={data => onSubmit({ facebook_id: data.id })}
              icon={<FaFacebookF />}
              render={renderProps => (
                <ButtonFb onClick={renderProps.onClick}>
                  This is my custom FB button
                </ButtonFb>
              )}
            />
          </Box>
        </form>
      )}
    />
  );
}

export default LoginForm;
