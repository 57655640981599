import React, { useCallback, useEffect, useState } from 'react';

import { Card, Box, Typography, Button, TextField } from '@material-ui/core';

import { useStyles } from './styles';
import { When } from 'react-if';
import { getFormattedDate, getFormattedNumber } from 'utils/transform';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import markerInicio from 'images/marker-inicio.png';
import markerFim from 'images/marker-fim.png';

const TrajetoCard = ({
  route,
  deleteTrajeto,
  editTrajeto,
  disabled = false,
  index,
}) => {
  const classes = useStyles();
  const [map, setMap] = useState(null);

  const onMapLoad = useCallback(
    map => {
      const path = route.pontos_trajeto;

      const bounds = new google.maps.LatLngBounds();

      path?.map(position => {
        bounds?.extend(position);
      });

      map.fitBounds(bounds);

      setMap(map);
    },
    [route],
  );

  useEffect(() => {
    if (!map) return;

    const path = route.pontos_trajeto;

    const bounds = new google.maps.LatLngBounds();

    path?.map(position => {
      bounds.extend(position);
    });

    map.fitBounds(bounds);
  }, [route]);

  return (
    <Box className={classes.container}>
      <Card key={route.id} className={classes.routeCard}>
        <Box className={classes.routeDetails}>
          <Typography variant="h6" color="primary">
            Trajeto {index + 1}
          </Typography>
          <Box>
            <Typography style={{ fontWeight: 600 }}>Endereço</Typography>
            <Box className={classes.inputField}>
              <Typography>Partida</Typography>
              <TextField
                variant="outlined"
                disabled
                value={route.partida_ponto_endereco}
              />
            </Box>
            <When condition={!disabled}>
              <Button variant="contained" color="primary" onClick={editTrajeto}>
                Editar trajeto
              </Button>
            </When>
            <Box className={classes.inputField}>
              <Typography>Chegada</Typography>
              <TextField
                variant="outlined"
                disabled
                value={route.chegada_ponto_endereco}
              />
            </Box>
          </Box>
          <Box>
            <Typography style={{ fontWeight: 600 }}>Data e hora</Typography>
            <Box className={classes.inputField}>
              <Typography>Partida</Typography>
              <TextField
                variant="outlined"
                disabled
                value={getFormattedDate(route.data_partida, 'Pp')}
              />
            </Box>
            <Box className={classes.inputField}>
              <Typography>Chegada</Typography>
              <TextField
                variant="outlined"
                disabled
                value={getFormattedDate(route.data_chegada, 'Pp')}
              />
            </Box>
          </Box>
          <Box className={classes.distance}>
            <Typography variant="caption">Distância</Typography>
            <Typography>
              {getFormattedNumber(route.distancia / 1000)}km
            </Typography>
          </Box>
        </Box>
        <GoogleMap
          id="map"
          onLoad={onMapLoad}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          clickableIcons={false}
          zoom={20}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            zoomControl: false,
            gestureHandling: 'none',
          }}
        >
          <Marker position={route.partida_ponto} icon={markerInicio} />
          <Marker position={route.chegada_ponto} icon={markerFim} />
          <Polyline
            path={route.pontos_trajeto}
            options={{
              geodesic: true,
              strokeColor: '#563777',
              strokeWeight: 4,
            }}
          />
        </GoogleMap>

        <When condition={!disabled}>
          <IconButton
            className={classes.deleteRouteButton}
            onClick={deleteTrajeto}
          >
            <Delete />
          </IconButton>
        </When>
      </Card>
    </Box>
  );
};

export default React.memo(TrajetoCard);
