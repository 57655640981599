import { useAuth } from 'contexts/auth';
import React from 'react';
import { useLocation, matchPath, Redirect } from 'react-router-dom';
import config from 'routes/config';

function LoggedLayout(props) {
  const { children } = props;
  const { signed } = useAuth();
  let location = useLocation();

  const activeRoute = Object.keys(config)
    .map(key => config[key])
    .flat()
    .find(route => matchPath(location.pathname, route));

  if (!signed) {
    return <Redirect to="/login" />;
  }

  if (!activeRoute) {
    return <Redirect to="/" />;
  }

  return children;
}

export default LoggedLayout;
