import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';

import DataTable from 'components/DataTable';
import Header from 'components/Header/Header';

import useTable from 'hooks/useTable';

import { useStyles } from 'pages/HomePage/styles';
import { getFormattedDate, getFormattedNumber } from 'utils/transform';
import { TIPO_ATIVIDADE } from 'utils/constants';
import formatStringByPattern from 'format-string-by-pattern';
import ActivityDialog from 'dialogs/ActivityDialog';

function Activities() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [activity, setActivity] = useState({});
  const { data, options, ...dataTableProps } = useTable(
    'atividades-admin',
    {
      onRowClick,
      download: false,
      print: false,
      sortColumns: false,
      viewColumns: false,
    },
    { include: 'user' },
    'filter',
  );

  const columns = [
    {
      id: 'email',
      name: 'user',
      label: 'E-mail',
      options: {
        customBodyRender: user => user?.email,
      },
    },
    {
      id: 'cpf',
      name: 'user',
      label: 'CPF',
      options: {
        customBodyRender: user =>
          formatStringByPattern('XXX.XXX.XXX-XX', user?.cpf),
      },
    },
    {
      name: 'tipo_atividade',
      label: 'Tipo',
      options: {
        customBodyRender: value => TIPO_ATIVIDADE[value]?.label,
      },
    },
    {
      name: 'tempo_atividade',
      label: 'Duração',
      options: {
        customBodyRender: value => `${getFormattedNumber(value)} min`,
      },
    },
    {
      name: 'distancia',
      label: 'Distância',
      options: {
        customBodyRender: value => `${getFormattedNumber(value / 1000)} km`,
      },
    },
    {
      name: 'data_fim',
      label: 'Data',
      options: {
        customBodyRender: value => getFormattedDate(value),
      },
    },
  ];

  function onRowClick(row) {
    setOpen(true);
    setActivity(row);
  }

  return (
    <Box className={classes.container}>
      <Header />
      <Card className={classes.eventsContainer} style={{ width: '1100px' }}>
        <DataTable
          title="Atividades"
          columns={columns}
          data={data}
          options={{
            ...options,
            searchPlaceholder: 'Buscar por e-mail ou CPF',
          }}
          {...dataTableProps}
        />
      </Card>
      <ActivityDialog
        open={open}
        onClose={() => {
          setOpen(false);
          setActivity({});
        }}
        initialValues={activity}
      />
    </Box>
  );
}

export default Activities;
