export const STATUS_EVENTO_INCOMPLETO = 0;
export const STATUS_EVENTO_COMPLETO = 1;

export const EVENTO_ONLINE = 0;
export const EVENTO_PRESENCIAL = 1;

export const EVENTO_MODALIDADE = [
  {
    label: 'Presencial',
    value: EVENTO_PRESENCIAL,
  },
  {
    label: 'Virtual',
    value: EVENTO_ONLINE,
  },
];

export const EVENT_EXERCISE_TYPE_FORM = 0;
export const EVENT_MODALITY_FORM = 1;
export const EVENT_PHOTO_AND_NAME_FORM = 2;
export const EVENT_DESCRIPTION_AND_RULES_FORM = 3;
export const EVENT_BIKE_TYPE_FORM = 4;
export const EVENT_RECURRENCE_FORM = 5;
export const EVENT_RECURRENCE_TYPE_FORM = 6;
export const EVENT_PRIVACY_FORM = 7;
export const EVENT_TYPE_FORM = 8;
export const EVENT_OBJECTIVE_FORM = 9;
export const EVENT_STRUCTURE_FORM = 10;
export const EVENT_ROUTE_FORM = 11;
export const EVENT_ROUTE_MAP_FORM = 12;
export const EVENT_ROUTES_TRANSITION = 13;
export const EVENT_OVERALL_FORM = 14;

export const CICLISMO = 0;
export const CORRIDA = 1;

export const TIPO_ATIVIDADE = [
  {
    label: 'Ciclismo',
    value: CICLISMO,
  },
  {
    label: 'Corrida',
    value: CORRIDA,
  },
];

export const BIKE_ESTRADA = 0;
export const BIKE_MTB = 1;
export const BIKE_URBANA = 2;
export const BIKE_ASSISTIDA = 3;
export const BIKE_HANDBIKE = 4;
export const BIKE_OUTRA = 5;

export const TIPOS_BIKE = [
  {
    label: 'Todas',
    value: String(null),
  },
  {
    label: 'Estrada',
    value: String(BIKE_ESTRADA),
  },
  {
    label: 'Mountain Bike',
    value: String(BIKE_MTB),
  },
  {
    label: 'Urbana',
    value: String(BIKE_URBANA),
  },
  {
    label: 'Assistida',
    value: String(BIKE_ASSISTIDA),
  },
  {
    label: 'Handbike',
    value: String(BIKE_HANDBIKE),
  },
  {
    label: 'Outra',
    value: String(BIKE_OUTRA),
  },
];

export const RECORRENCIA_PONTUAL = 0;
export const RECORRENCIA_RECORRENTE = 1;

export const RECORRENCIA_DIARIA = 0;
export const RECORRENCIA_SEMANAL = 1;
export const RECORRENCIA_MENSAL = 2;

export const EVENTO_PUBLICO = 0;
export const EVENTO_PRIVADO = 1;

export const EVENTO_VISIBILIDADE = [
  {
    label: 'Público',
    value: EVENTO_PUBLICO,
  },
  {
    label: 'Privado',
    value: EVENTO_PRIVADO,
  },
];

export const COMPETICAO = 0;
export const PASSEIO_CAMINHADA = 1;
export const TREINO = 2;
export const DESAFIO = 3;
export const CICLOTURISMO_ECOAVENTURA = 4;

export const TIPOS_EVENTO_CICLISMO = [
  {
    label: 'Competição',
    value: COMPETICAO,
  },
  {
    label: 'Passeio',
    value: PASSEIO_CAMINHADA,
  },
  {
    label: 'Treino',
    value: TREINO,
  },
  {
    label: 'Desafio',
    value: DESAFIO,
  },
  {
    label: 'Cicloturismo',
    value: CICLOTURISMO_ECOAVENTURA,
  },
];

export const TIPOS_EVENTO_CORRIDA = [
  {
    label: 'Competição',
    value: COMPETICAO,
  },
  {
    label: 'Caminhada',
    value: PASSEIO_CAMINHADA,
  },
  {
    label: 'Treino',
    value: TREINO,
  },
  {
    label: 'Desafio',
    value: DESAFIO,
  },
  {
    label: 'Eco-aventura',
    value: CICLOTURISMO_ECOAVENTURA,
  },
];

export const BANHEIRO = 1;
export const APOIO_MEDICO = 2;
export const CARRO_APOIO = 3;
export const BATEDOR = 4;
export const APOIO_MECANICO = 5;
export const ALIMENTACAO = 6;

export const DEFAULT_RESULTS_ARRAY = [
  { participante: null, hours: null, minutes: null },
  { participante: '', hours: '', minutes: '' },
  { participante: '', hours: '', minutes: '' },
];

export const DEFAULT_STRUCTURE_ARRAY = [
  {
    id: BANHEIRO,
    nome: 'Banheiro',
    prop: 'banheiro',
    existe: false,
  },
  {
    id: APOIO_MEDICO,
    nome: 'Apoio Medico',
    prop: 'medico',
    existe: false,
  },
  {
    id: CARRO_APOIO,
    nome: 'Carro Apoio',
    prop: 'carro_apoio',
    existe: false,
  },
  {
    id: BATEDOR,
    nome: 'Batedor',
    prop: 'batedor',
    existe: false,
  },
  {
    id: APOIO_MECANICO,
    nome: 'Apoio Mecânico',
    prop: 'apoio_mecanico',
    existe: false,
  },
  {
    id: ALIMENTACAO,
    nome: 'Alimentação',
    prop: 'alimentacao',
    existe: false,
  },
];

export const SOCIAL_NETWORKS = {
  facebook: 'https://www.facebook.com/oiroapp',
  instagram: 'https://www.instagram.com/oiro.app/',
};

export const ADMIN_PROFILE = 1;
export const ATLETA_PROFILE = 2;

export const PROFILES_LABEL = {
  [ADMIN_PROFILE]: 'Administrador',
  [ATLETA_PROFILE]: 'Atleta',
};

export const STATES = [
  { id: undefined, label: '', value: '-' },
  { id: 12, label: 'Acre', value: 'AC' },
  { id: 27, label: 'Alagoas', value: 'AL' },
  { id: 16, label: 'Amapá', value: 'AP' },
  { id: 13, label: 'Amazonas', value: 'AM' },
  { id: 29, label: 'Bahia', value: 'BA' },
  { id: 23, label: 'Ceará', value: 'CE' },
  { id: 53, label: 'Distrito Federal', value: 'DF' },
  { id: 32, label: 'Espírito Santo', value: 'ES' },
  { id: 52, label: 'Goiás', value: 'GO' },
  { id: 21, label: 'Maranhão', value: 'MA' },
  { id: 51, label: 'Mato Grosso', value: 'MT' },
  { id: 50, label: 'Mato Grosso do Sul', value: 'MS' },
  { id: 31, label: 'Minas Gerais', value: 'MG' },
  { id: 15, label: 'Pará', value: 'PA' },
  { id: 25, label: 'Paraíba', value: 'PB' },
  { id: 41, label: 'Paraná', value: 'PR' },
  { id: 26, label: 'Pernambuco', value: 'PE' },
  { id: 22, label: 'Piauí', value: 'PI' },
  { id: 33, label: 'Rio de Janeiro', value: 'RJ' },
  { id: 24, label: 'Rio Grande do Norte', value: 'RN' },
  { id: 43, label: 'Rio Grande do Sul', value: 'RS' },
  { id: 11, label: 'Rondônia', value: 'RO' },
  { id: 14, label: 'Roraima', value: 'RR' },
  { id: 42, label: 'Santa Catarina', value: 'SC' },
  { id: 35, label: 'São Paulo', value: 'SP' },
  { id: 28, label: 'Sergipe', value: 'SE' },
  { id: 17, label: 'Tocantins', value: 'TO' },
];

export const STATUS_PENDENTE = 0;
export const STATUS_APROVADO = 1;
export const STATUS_REPROVADO = 2;

export const STATUS_APPROVAL_COLOR = {
  [STATUS_PENDENTE]: '#FFB800',
  [STATUS_APROVADO]: '#42FF00',
  [STATUS_REPROVADO]: '#EF0E00',
};

export const STATUS_APPROVAL_NAME = {
  [0]: 'Pendente',
  [1]: 'Aprovado',
  [2]: 'Reprovado',
};
