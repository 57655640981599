import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  FileBoxContainer: {
    width: '250px',
    height: '60px',
    padding: '16px 0px 0px 0px',
    borderRadius: '8px',
    border: '0.5px solid',
    borderColor: '#ababab',
    overflow: 'hidden',
    marginLeft: '10px',
  },
  Rectangle: {
    width: '250px',
    height: '10px',
    marginTop: '8px',
    background: '#563777',
  },

  FileBoxTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '4px',
    paddingLeft: '20px',
    paddingRight: '20px',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  FileBoxText: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',
  },
}));
