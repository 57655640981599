import React from 'react';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt';

import GlobalStyle from 'styles/global';
import theme from 'config/theme';
import Routes from 'routes';
import { AuthProvider } from 'contexts/auth';
import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];

function App() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    language: 'pt-BR',
  });

  if (!isLoaded) return null;

  return (
    <AuthProvider>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
              <Routes />
              <GlobalStyle />
              <ToastContainer autoClose={3000} />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </AuthProvider>
  );
}

export default App;
