import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    width: 800,
    position: 'relative',
  },
  routeCard: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  routeDetails: {
    width: 330,
    padding: 25,
    borderRight: '1px solid #d6d6d6',
    '& .MuiButton-root': {
      height: 35,
      borderRadius: 14,
      marginBottom: 10,
      marginLeft: '30%',
    },
  },
  inputField: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    justifyContent: 'flex-end',
    '& .MuiInputBase-root': {
      width: 190,
      marginLeft: 10,
      height: 40,
    },
  },
  distance: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30,
    '& .MuiTypography-root': {
      marginRight: 20,
      fontWeight: 'bold',
    },
  },
  deleteRouteButton: {
    position: 'absolute',
    right: -20,
    top: -20,
    backgroundColor: '#F07070',
    color: 'white',
    borderRadius: '50%',
    width: 40,
    height: 40,
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: '#F09090',
    },
  },
});
