import React from 'react';

import { DirectionsRenderer } from '@react-google-maps/api';

class CustomDirections extends React.Component {
  constructor(props) {
    super(props);
    this.directionsRendererRef = React.createRef(null);
  }

  onDirectionsChanged = () => {
    if (this.directionsRendererRef.current === null) return;

    const directions = this.directionsRendererRef.current.state
      .directionsRenderer.directions;

    this.props.handleRouteDrag(directions);
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps.directionsResponse !== this.props.directionsResponse) {
      return true;
    }

    return false;
  }

  render() {
    const props = this.props;
    const directionsRendererRef = this.directionsRendererRef;

    if (!props.directionsResponse) return null;

    return (
      <DirectionsRenderer
        ref={directionsRendererRef}
        onDirectionsChanged={this.onDirectionsChanged}
        options={{
          directions: props.directionsResponse,
          draggable: true,
          preserveViewport: true,
          markerOptions: {
            draggable: true,
          },
          polylineOptions: {
            strokeColor: '#563777',
            strokeWeight: 6,
          },
          ...props.options,
        }}
      />
    );
  }
}

export default CustomDirections;
