import React, { useEffect, useState } from 'react';

import Centered from 'components/Centered';
import { Typography } from '@material-ui/core';
import { FormContainer } from 'styles/global';
import ResetPasswordForm from 'forms/ResetPasswordForm';
import { useHistory, useParams } from 'react-router-dom';
import api from 'services/api';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';

const ResetPassword = ({ create = false }) => {
  const { token } = useParams();
  const history = useHistory();
  const [success, setSuccess] = useState(false);

  const onSubmit = async values => {
    try {
      await api.post(!create ? 'auth/update-password' : 'create-password', {
        token,
        password: values.password,
        confirm_password: create ? values.password : undefined,
      });

      if (!isMobile) {
        return history.push('/login');
      }

      setSuccess(true);
    } catch (err) {
      if (err?.response?.status === 500) return;
      toast.error(
        err?.response?.msg ||
          err?.response?.message ||
          `Tempo para ${
            create ? 'criação' : 'troca'
          } de senha, expirou, entre em contato com o suporte.`,
      );
    }
  };

  useEffect(() => {
    if (!token) {
      history.push('/login');
    }
  }, []);

  return (
    <Centered column>
      {!success ? (
        <>
          <Typography color="primary" variant="h6" mb={2} align="center">
            {create ? 'Criar' : 'Alterar'} senha
          </Typography>
          <FormContainer>
            <ResetPasswordForm onSubmit={onSubmit} />
          </FormContainer>
        </>
      ) : (
        <>
          <Typography color="primary" variant="h6" mb={2} align="center">
            Senha {create ? 'criada' : 'alterada'} com sucesso!
          </Typography>
          <Typography mt={2} align="center">
            Faça login no aplicativo Oiro!
          </Typography>
        </>
      )}
    </Centered>
  );
};

export default ResetPassword;
