import Ws from '@adonisjs/websocket-client';

function connection() {
  const ws = Ws(process.env.REACT_APP_API_URL_WS, { path: '' });

  ws.connect();

  return new Promise(resolve => {
    ws.on('open', () => {
      resolve(ws);
    });
  });
}

export default connection;
