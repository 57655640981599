import React from 'react';
import DialogForm from 'components/DialogForm';
import { Button, Grid, Typography } from '@material-ui/core';
import { TIPO_ATIVIDADE } from 'utils/constants';
import { getFormattedDate, getFormattedNumber } from 'utils/transform';
import formatStringByPattern from 'format-string-by-pattern';

function ActivityDialog({ initialValues, onClose = () => {}, open = false }) {
  return (
    <div>
      <DialogForm
        title={initialValues.nome}
        open={open}
        onClose={onClose}
        maxWidth="sm"
      >
        {() => (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography color="primary" style={{ fontWeight: 'bold' }}>
                E-mail:
              </Typography>
              <Typography>{initialValues.user?.email}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary" style={{ fontWeight: 'bold' }}>
                CPF:
              </Typography>
              <Typography>
                {formatStringByPattern(
                  'XXX.XXX.XXX-XX',
                  initialValues.user?.cpf,
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary" style={{ fontWeight: 'bold' }}>
                Tipo de atividade:
              </Typography>
              <Typography>
                {TIPO_ATIVIDADE[initialValues.tipo_atividade]?.label}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary" style={{ fontWeight: 'bold' }}>
                Distância percorrida:
              </Typography>
              <Typography>
                {getFormattedNumber(initialValues.distancia / 1000)} km
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary" style={{ fontWeight: 'bold' }}>
                Velocidade máxima:
              </Typography>
              <Typography>
                {getFormattedNumber(initialValues.velocidade_max)} km/h
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary" style={{ fontWeight: 'bold' }}>
                Velocidade média:
              </Typography>
              <Typography>
                {getFormattedNumber(initialValues.velocidade_media)} km/h
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary" style={{ fontWeight: 'bold' }}>
                Altimetria:
              </Typography>
              <Typography>
                {getFormattedNumber(initialValues.altimetria)} metros
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary" style={{ fontWeight: 'bold' }}>
                Duração da atividade:
              </Typography>
              <Typography>
                {getFormattedNumber(initialValues.tempo_atividade)} min
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary" style={{ fontWeight: 'bold' }}>
                Data de início:
              </Typography>
              <Typography>
                {getFormattedDate(initialValues.data_inicio, 'Pp')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary" style={{ fontWeight: 'bold' }}>
                Data de término:
              </Typography>
              <Typography>
                {getFormattedDate(initialValues.data_fim, 'Pp')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={onClose}
                style={{ marginTop: '10px' }}
              >
                Fechar
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogForm>
    </div>
  );
}

export default ActivityDialog;
