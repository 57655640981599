import React from 'react';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from '@material-ui/core';
import { Field } from 'react-final-form';
import { Radio as MuiRadio } from 'final-form-material-ui';
import { useStyles } from './styles';

const Radio = ({ name = 'options', options = [], label, disabled = false }) => {
  const classes = useStyles();

  return (
    <FormControl
      component="fieldset"
      className={classes.formControl}
      disabled={disabled}
    >
      {label && (
        <FormLabel focused color="primary" component="span">
          {label}
        </FormLabel>
      )}
      <RadioGroup row className={classes.radioGroup}>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            label={option.label}
            control={
              <Field
                name={name}
                color="primary"
                type="radio"
                icon={<span className={classes.icon} />}
                checkedIcon={<span className={classes.checkedIcon} />}
                component={MuiRadio}
                value={String(option.value)}
              />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default Radio;
