import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#f5f5f5',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: 40,
  },
  headerLayout: {
    display: 'flex',
    maxWidth: 800,
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 20,
    color: 'white',
  },
  layout: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    maxWidth: 800,
    margin: 'auto',
    marginTop: 40,
    color: theme.palette.primary.main,
    marginBottom: 200,
    textAlign: 'justify',
  },
}));
