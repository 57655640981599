/* eslint-disable no-unreachable */
import React, { createContext, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import api from 'services/api';
import { ADMIN_PROFILE, STATUS_APROVADO } from 'utils/constants';

const AuthContext = createContext({});

export function login(values) {
  return api.post('auth/login', values);
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function setInterceptors() {
      api.interceptors.response.use(
        function(response) {
          return response;
        },
        function(error) {
          if (error?.response?.status === 403) {
            toast.error(error.response.data.message);

            return Promise.reject(error);
          }

          if (error?.response?.status === 401) {
            const signed = !!localStorage.getItem('@token');

            if (signed) {
              toast.error('Sua sessão expirou, logue novamente.');
              signOut();
            } else {
              if (error.response.config.url.includes('login')) {
                toast.error('Credenciais inválidas');
              }
            }

            return Promise.reject(error);
          }

          if (error?.response?.status !== 422) {
            toast.error(
              'Ops… ocorreu uma falha na sua requisição, tente novamente em instantes.',
            );
          }

          return Promise.reject(error);
        },
      );
    }

    function loadStoragedData() {
      const storagedUser = localStorage.getItem('@user');
      const storagedToken = localStorage.getItem('@token');
      console.log(JSON.parse(storagedUser));

      if (storagedUser && storagedToken) {
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
        setUser(JSON.parse(storagedUser));
      }
      setLoading(false);
    }

    setInterceptors();
    loadStoragedData();
  }, []);

  async function signIn(values) {
    const { data: response } = await login(values);

    if (
      response.profiles[0].id === ADMIN_PROFILE ||
      response.user.status_approval === STATUS_APROVADO
    ) {
      saveCredencials(response);
      setAuthorizationHeader(response);

      return;
    }

    toast.error('Usuário pendente ou reprovado.');
    return signOut();
  }

  async function signInWebSocket(response) {
    if (
      response.profiles[0].id === ADMIN_PROFILE ||
      response.user.status_approval === STATUS_APROVADO
    ) {
      saveCredencials(response);
      setAuthorizationHeader(response);

      return;
    }
    toast.error('Usuário pendente ou reprovado.');
    return signOut();
  }

  function saveCredencials({ user, access_token, profiles }) {
    const storeUser = { ...user, profiles: profiles.map(item => item.id) };
    setUser(storeUser);

    localStorage.setItem('@user', JSON.stringify(storeUser));
    localStorage.setItem('@token', access_token.token);
  }

  function setAuthorizationHeader({ access_token }) {
    api.defaults.headers.Authorization = `Bearer ${access_token.token}`;
  }

  async function signOut() {
    localStorage.clear();
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        profiles: user?.profiles,
        signIn,
        signOut,
        loading,
        signInWebSocket,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
