import styled, { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.min.css';
import theme from 'config/theme';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
    margin: 0;
  }

  html {
    word-break: normal;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  body {
    background-color: #f5f5f5;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  .MuiFormControl-marginNormal {
    margin-top: 10px;
  }

  textarea {
    overflow-y: scroll;
  }

  textarea::-webkit-scrollbar {
    width: 5px;
  }

  textarea::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 10px;
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 10px;
  }

  .pac-container {
    border-radius: 16px;
    padding: 5px;
    font-family: 'Montserrat';
    z-index: 9999;
  }

  .pac-item {
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }

  .pac-item span:not(.pac-item-query) {
    margin-top: -10px;
  }

  .pac-icon {
    display: none;
  }

  .pac-matched {
    color: ${theme.palette.primary.main};
  }

  .pac-logo::after {
    display: none
  }
`;

export const FormContainer = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  background: #f5f5f5;
`;

export default GlobalStyle;
