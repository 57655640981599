import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    height: '100%',
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: '7%',
    height: '100%',
    '& .MuiInputBase-root': {
      height: 40,
      borderRadius: 14,
      marginTop: 5,
      marginBottom: 15,
      width: 300,
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    '& .MuiButton-root': {
      height: 35,
      borderRadius: 14,
      marginBottom: 10,
    },
  },
  routeDetails: {
    '& .MuiTypography-root': {
      fontWeight: 'inherit',
      marginBottom: 5,
    },
  },
  rightContent: {
    '& .MuiInputBase-root': {
      width: 230,
    },
    '& .MuiButton-root': {
      visibility: 'hidden',
    },
  },
});
