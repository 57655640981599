import React from 'react';

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Box,
} from '@material-ui/core';

const ConfirmDialog = ({
  onClose = () => {},
  open = false,
  onAction = () => {},
  title = 'Deseja prosseguir?',
  content = 'Essa ação é irreversível.',
  actions = [
    {
      label: 'Cancelar',
      buttonColor: 'primary',
      buttonVariant: 'outlined',
      action: () => {},
    },
    {
      label: 'Confirmar',
      buttonColor: 'primary',
      buttonVariant: 'contained',
      action: () => {
        onAction();
      },
    },
  ],
}) => {
  return (
    <div>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography>{content}</Typography>

          <Box height={20} />
          <Grid container spacing={2} justify="flex-end">
            {actions.map((action, index) => (
              <Grid key={`${action.label}-${index}`} item>
                <Button
                  variant={action.buttonVariant}
                  color={action.buttonColor}
                  onClick={() => {
                    action.action();
                    onClose();
                  }}
                >
                  {action.label}
                </Button>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConfirmDialog;
