import React from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import { useAuth } from 'contexts/auth';
import OiroLogo from 'images/logo.svg';
import { ExitToApp, Search } from '@material-ui/icons';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { When } from 'react-if';
import { ADMIN_PROFILE } from 'utils/constants';
import debounce from 'lodash.debounce';

const Header = ({ isPublicPage = false, onSearch, showApprove = false }) => {
  const { user, signOut, profiles } = useAuth();
  const isAdmin = profiles?.includes(ADMIN_PROFILE);
  const classes = useStyles();
  const history = useHistory();

  const navigateToCreateEvent = () => {
    history.push('/new');
  };

  const navigateToUsers = () => {
    history.push('/usuarios');
  };

  const navigateToActivities = () => {
    history.push('/atividades');
  };

  const navigateToHome = () => {
    history.push('/');
  };

  const navigateToApproveUsers = () => {
    history.push('/aprovacoes');
  };

  return (
    <Box className={classes.header}>
      <Grid container className={classes.layout} spacing={0}>
        <Grid item container xs={12} style={{ marginBottom: 20 }}>
          <Grid container item xs={6}>
            <Box className={classes.imageWrapper}>
              <img src={OiroLogo} width={90} />
              <Typography className={classes.pageName}>Home</Typography>
            </Box>
          </Grid>
          <Grid container item xs={6} justify="flex-end" alignItems="center">
            <Typography className={classes.greetingText} variant="h6">
              {!isPublicPage && `Olá, ${user?.name}!`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.navBar}>
          <When condition={!isPublicPage}>
            <Grid item container xs={11}>
              <ButtonGroup className={classes.buttonGroup}>
                <Button onClick={navigateToHome}>Home</Button>
                {isAdmin && <Button onClick={navigateToUsers}>Usuários</Button>}
                {isAdmin && (
                  <Button onClick={navigateToActivities}>Atividades</Button>
                )}
                <Button onClick={navigateToCreateEvent}>Criar evento</Button>
                {isAdmin ? (
                  <Button onClick={navigateToApproveUsers}>Aprovações</Button>
                ) : (
                  <></>
                )}
              </ButtonGroup>
            </Grid>
            <Grid item container xs={1} justify="flex-end">
              <IconButton
                onClick={signOut}
                color="primary"
                className={classes.logoutButton}
              >
                <ExitToApp />
              </IconButton>
            </Grid>
            <Grid item container xs={12} className={classes.navBar}>
              {onSearch && (
                <Grid item container xs={3} justify="center">
                  <TextField
                    className={classes.searchInput}
                    variant="outlined"
                    style={{ width: '220px' }}
                    onChange={debounce(onSearch, 500)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <IconButton edge="start" color="primary">
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </When>
        </Grid>
      </Grid>
      <Box className={classes.bottomHeader}></Box>
    </Box>
  );
};

export default Header;
