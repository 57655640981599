import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  formContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 15,
  },
  objectiveInput: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiTypography-root:not(h5)': {
      fontWeight: 600,
    },
    '& .MuiInputBase-root': {
      width: 90,
      marginLeft: 7,
      marginRight: 7,
      height: 45,
    },
  },
  prazoInput: {
    '& .MuiInputBase-root': {
      width: 200,
    },
  },
});
