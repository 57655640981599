import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  buttonsContainer: {
    display: 'flex',
    marginTop: 10,
    justifyContent: 'center',
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: 15,
      marginRight: 15,
      maxWidth: 80,
    },
    '& .MuiButton-root': {
      width: 30,
      height: 64,
      borderRadius: '50%',
    },
    '& .MuiTypography-root': {
      fontWeight: 600,
      marginTop: 5,
      textAlign: 'center',
      color: theme.palette.primary.main,
    },
  },
  smallButtonsContainer: {
    display: 'flex',
    marginTop: 10,
    justifyContent: 'center',
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 65,
    },
    '& .MuiButton-root': {
      width: 50,
      maxHeight: 50,
      minWidth: 50,
      height: 50,
      borderRadius: '50%',
    },
    '& .MuiTypography-root': {
      marginTop: 5,
      fontWeight: 'bold',
      fontSize: 10,
      textAlign: 'center',
      color: theme.palette.primary.main,
    },
  },
}));
