import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#f5f5f5',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  eventsContainer: {
    marginTop: '3%',
    width: 800,
    minHeight: 500,
    margin: 'auto',
    padding: 25,
    position: 'relative',
    justifyContent: 'center',
    zIndex: 2,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
  },
  tabs: {
    height: 5,
  },
  tab: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'center',
    textTransform: 'capitalize',
    border: 1,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    marginRight: 15,
  },
  unselectedTab: {
    background: '#DFDFDF',
  },
}));
