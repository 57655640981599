import React, { lazy } from 'react';

import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import Login from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import EventoDetalhe from 'pages/EventoDetalhe';
import Activities from 'pages/Activities';
import ApproveUsers from 'pages/ApproveUsers/ApproveUsers';
import PrivacyAndPolicy from 'pages/PrivacyAndPolicy';

const HomePage = lazy(() => import('pages/HomePage'));
const Users = lazy(() => import('pages/Users'));
const Profile = lazy(() => import('pages/Profile'));
const CreateEvent = lazy(() => import('pages/CreateEvent'));
const EventosParticipantes = lazy(() => import('pages/EventosParticipantes'));
const EventosResultado = lazy(() => import('pages/EventosResultado'));
const EventoCriado = lazy(() => import('pages/EventoCriado'));
const EventoLink = lazy(() => import('pages/EventoLink'));

const config = {
  public: [
    {
      path: '/login',
      component: Login,
      exact: true,
    },
    {
      path: '/esqueci-minha-senha',
      component: ForgotPassword,
      exact: true,
    },
    {
      path: '/criar-senha/:token',
      component: () => <ResetPassword create />,
      exact: true,
    },
    {
      path: '/reset-password/:token',
      component: ResetPassword,
      exact: true,
    },
    {
      path: '/private/evento/:id',
      component: EventoDetalhe,
      exact: true,
    },
    {
      path: '/privacy-and-policy',
      component: PrivacyAndPolicy,
      exact: true,
    },
  ],
  protected: [
    {
      path: '/',
      component: HomePage,
      title: 'Início',
      exact: true,
      icon: <DashboardIcon />,
    },
    {
      path: '/new',
      component: CreateEvent,
      title: 'Criar Evento',
      exact: true,
    },
    {
      path: '/usuarios',
      component: Users,
      title: 'Usuários',
      exact: true,
      icon: <PeopleIcon />,
    },
    {
      path: '/atividades',
      component: Activities,
      title: 'Atividades',
      exact: true,
      icon: <PeopleIcon />,
    },
    {
      path: '/aprovacoes',
      component: ApproveUsers,
      title: 'Aprovações',
      exact: true,
      icon: <PeopleIcon />,
    },
    {
      path: '/profile',
      component: Profile,
      title: 'Perfil',
      exact: true,
      icon: <AccountBoxIcon />,
    },
    {
      path: '/eventos/:id',
      component: EventoDetalhe,
      exact: true,
    },
    {
      path: '/eventos/:id/participantes',
      component: EventosParticipantes,
      exact: true,
    },
    {
      path: '/eventos/:id/resultado',
      component: EventosResultado,
      exact: true,
    },
    {
      path: '/eventos/:id/share',
      component: EventoCriado,
      exact: true,
    },
    {
      path: '/eventos/:id/link',
      component: EventoLink,
      exact: true,
    },
  ],
};

export default config;
