import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  eventDetailsCard: {
    marginTop: '3%',
    width: 800,
    minHeight: 530,
    margin: 'auto',
    padding: 25,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 2,
    '& .MuiTypography-root': {
      marginBottom: 5,
    },
  },
  eventDescription: {
    marginTop: 15,
    '& .MuiInputBase-root': {
      height: 'fit-content',
    },
  },
  eventName: {
    '& .MuiInputBase-root': {
      height: 45,
    },
  },
  bottomDetailsCards: {
    margin: 'auto',
    marginTop: 40,
    width: 800,
  },
  bikeTypeAndEventTypeCard: {
    height: 320,
    marginRight: 13,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiBox-root': {
      marginTop: 5,
      marginBottom: 5,
    },
    '& .MuiInputBase-root': {
      width: 320,
    },
  },
  structureCard: {
    height: 320,
    marginLeft: 13,
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > .MuiTypography-root': {
      fontSize: 20,
    },
  },
  routesList: {
    marginTop: 20,
    marginBottom: 20,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: 800,
    alignItems: 'center',
    '& .MuiTypography-root': {
      color: theme.palette.primary.main,
    },
  },
  footerCard: {
    width: 800,
    padding: 50,
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiButton-root': {
      height: 40,
      borderRadius: 15,
      '&:hover': {
        boxShadow: 'none',
      },
    },
    marginBottom: -20,
    marginTop: 20,
    zIndex: 2,
  },
}));
