import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    paddingTop: 10,
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  inputField: {
    '& .MuiIconButton-root': {
      height: 40,
      background: theme.palette.primary.main,
      width: 40,
      borderRadius: 12,
      marginLeft: 15,
    },
    '& .MuiInputBase-root': {
      height: 40,
      borderRadius: 14,
      marginBottom: 15,
      width: 300,
    },
  },
  mapContainer: {
    height: 600,
    width: '100%',
    borderRadius: 16,
    position: 'relative',
  },
  routeDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 10,
    '& .MuiTypography-root': {
      marginRight: 15,
    },
  },
  clearRouteButton: {
    backgroundColor: 'white',
    position: 'absolute',
    right: 10,
    top: 10,
    color: '#F07070',
    transition: 'all 0.3s',
    border: '2px solid white',
    '&:hover': {
      backgroundColor: 'white',
      border: '2px solid #F07070',
    },
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > svg': {
      marginRight: 15,
    },
  },
}));
