import React, { useEffect } from 'react';

import { Box, TextField, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { getFormattedNumber } from 'utils/transform';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { setDate } from 'date-fns';

const EventRoute = ({ value, onChange }) => {
  const classes = useStyles();

  const handlePartidaDateChange = date => {
    if (isNaN(date?.getTime())) return;

    const formattedDate = new Date(date);
    if (!value.data_chegada) {
      return onChange({
        ...value,
        data_partida: formattedDate?.toISOString(),
        data_chegada: setDate(
          formattedDate,
          formattedDate.getDate() + 1,
        ).toISOString(),
      });
    }

    onChange({ ...value, data_partida: date?.toISOString() });
  };

  const handleChegadaDateChange = date => {
    if (isNaN(date?.getTime())) return;

    onChange({ ...value, data_chegada: date?.toISOString() });
  };

  useEffect(() => {
    (async () => {
      handlePartidaDateChange(new Date());
    })();
  }, []);

  return (
    <Box className={classes.container}>
      <Typography variant="h6" color="primary">
        {value.nome}
      </Typography>
      <Box className={classes.formContainer}>
        <Box>
          <Box>
            <Typography color="primary">Endereço de partida</Typography>
            <TextField
              data-cy="endereco-partida"
              disabled
              value={value.partida_ponto_endereco}
              variant="outlined"
            />
          </Box>
          <Box>
            <Typography color="primary">Endereço de chegada</Typography>
            <TextField
              data-cy="endereco-chegada"
              disabled
              value={value.chegada_ponto_endereco}
              variant="outlined"
            />
          </Box>
          <Box className={classes.routeDetails}>
            <Typography color="primary">
              Distância{' '}
              <strong>{getFormattedNumber(value.distancia / 1000)}km</strong>
            </Typography>
          </Box>
        </Box>
        <Box className={classes.rightContent}>
          <Box>
            <Typography color="primary">Data de largada</Typography>
            <KeyboardDateTimePicker
              data-cy="data-largada"
              value={value.data_partida}
              KeyboardButtonProps={{
                style: { boxShadow: 'none' },
              }}
              ampm={false}
              autoOk
              onChange={handlePartidaDateChange}
              inputVariant="outlined"
              maxDate={value.data_chegada}
              disablePast
              maxDateMessage=""
              format="dd/MM/yyyy HH:mm"
            />
          </Box>
          <Box>
            <Typography color="primary">Data de chegada</Typography>
            <KeyboardDateTimePicker
              data-cy="data-chegada"
              value={value.data_chegada}
              KeyboardButtonProps={{
                style: { boxShadow: 'none' },
              }}
              autoOk
              ampm={false}
              onChange={handleChegadaDateChange}
              inputVariant="outlined"
              minDate={value.data_partida}
              minDateMessage=""
              format="dd/MM/yyyy HH:mm"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EventRoute;
