import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';

import DataTable from 'components/DataTable';
import Header from 'components/Header/Header';

import useTable from 'hooks/useTable';

import { useStyles } from './styles';

import formatStringByPattern from 'format-string-by-pattern';
import {
  Chip,
  Grid,
  Icon,
  IconButton,
  Input,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { STATUS_APPROVAL_COLOR, STATUS_APPROVAL_NAME } from 'utils/constants';
import ApproveUserDialog from 'dialogs/ApproveUserDialog.js/ApproveUserDialog';
import { MdCircle } from 'react-icons/md';
import api from 'services/api';
import SearchIcon from '@material-ui/icons/Search';

function ApproveUsers() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [countApproved, setCountApproved] = useState(0);
  const [countNonApproved, setCountNonApproved] = useState(0);
  const [countPending, setCountPending] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const [tableOptions, setTableOptions] = useState();

  const {
    data,
    onRefresh,
    options: { onSearchChange, ...options },
    ...dataTableProps
  } = useTable(
    'user',
    {
      onRowClick,
      download: false,
      print: false,
      sortColumns: false,
      viewColumns: false,
      search: false,
    },
    {
      status: true,
      profile_id: 2,
      include: 'documents',
      status_approval: tabIndex,
      documents: true,
    },
    'filter',
  );

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
    onRefresh({ ...tableOptions, status_approval: newIndex });
  };

  const columns = [
    {
      name: 'name',
      label: 'Nome',
    },
    {
      name: 'email',
      label: 'E-mail',
    },
    {
      name: 'cpf',
      label: 'CPF',
      options: {
        customBodyRender: cpf => formatStringByPattern('XXX.XXX.XXX-XX', cpf),
      },
    },
    {
      name: 'status_approval',
      label: 'Aprovação',
      options: {
        customBodyRender: status_approval => {
          return (
            <Chip
              label={STATUS_APPROVAL_NAME[status_approval]}
              style={{
                backgroundColor: STATUS_APPROVAL_COLOR[status_approval],
              }}
            />
          );
        },
      },
    },
  ];

  function onRowClick(row) {
    setOpen(true);
    setUser(row);
  }

  const fetchAllUsers = async () => {
    try {
      const { data } = await api.get('user', {
        params: {
          status: true,
          profile_id: 2,
          include: 'documents',
          documents: true,
          perPage: 'all',
        },
      });

      return data.data;
    } catch (error) {
      console.error('Erro ao obter todos os usuários:', error);
      return [];
    }
  };

  const calculateTotals = users => {
    setCountApproved(users.filter(d => d.status_approval === 1).length);
    setCountNonApproved(users.filter(d => d.status_approval === 2).length);
    setCountPending(users.filter(d => d.status_approval === 0).length);
  };

  useEffect(() => {
    const fetchAndCalculateTotals = async () => {
      const allUsers = await fetchAllUsers();

      calculateTotals(allUsers);
    };

    fetchAndCalculateTotals();
  }, [tabIndex]);

  const handleSearch = () => {
    const cleanedSearchQuery = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(searchQuery)
      ? searchQuery.replace(/[.-]/g, '')
      : searchQuery;

    onSearchChange(cleanedSearchQuery);
  };

  useEffect(() => {
    handleSearch();
  }, [searchQuery]);

  return (
    <Box className={classes.container}>
      <Header />
      <Card
        className={classes.eventsContainer}
        style={{ width: '1000px', overflow: 'inherit', minHeight: 'initial' }}
      >
        <Typography variant="h6" color="primary">
          Lista de aprovações
        </Typography>

        <Grid container>
          <Grid item xs={12} md={8}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
              textColor="primary"
              style={{
                marginTop: 20,
                marginBottom: -10,
              }}
            >
              <Tab
                className={`${classes.tab} ${tabIndex !== 0 &&
                  classes.unselectedTab}`}
                label={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <MdCircle
                      color={STATUS_APPROVAL_COLOR[0]}
                      style={{ marginRight: '8px' }}
                    />
                    Pendente ({countPending})
                  </span>
                }
              />
              <Tab
                className={`${classes.tab} ${tabIndex !== 1 &&
                  classes.unselectedTab}`}
                label={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <MdCircle
                      color={STATUS_APPROVAL_COLOR[1]}
                      style={{ marginRight: '8px' }}
                    />
                    Aprovado ({countApproved})
                  </span>
                }
              />
              <Tab
                className={`${classes.tab} ${tabIndex !== 2 &&
                  classes.unselectedTab}`}
                label={
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <MdCircle
                      color={STATUS_APPROVAL_COLOR[2]}
                      style={{ marginRight: '8px' }}
                    />
                    Reprovado ({countNonApproved})
                  </span>
                }
              />
            </Tabs>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TextField
              id="search"
              variant="standard"
              placeholder="Busca por email ou cpf"
              style={{
                width: '100%',
                marginRight: 5,
              }}
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
            <SearchIcon color="action" />
          </Grid>
        </Grid>
        <DataTable
          columns={columns}
          data={data}
          options={options}
          {...dataTableProps}
        />
      </Card>

      <ApproveUserDialog
        open={open}
        onClose={() => {
          setUser({});
          setOpen(false);
          onRefresh();
        }}
        initialValues={user}
      />
    </Box>
  );
}

export default ApproveUsers;
