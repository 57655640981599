import React from 'react';

import { Box, IconButton } from '@material-ui/core';
import { Mail } from '@material-ui/icons';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import OiroLogo from 'images/logo.svg';
import { useStyles } from './styles';
import { SOCIAL_NETWORKS } from 'utils/constants';

const Footer = ({ additionalStyles }) => {
  const classes = useStyles();
  const { facebook, instagram } = SOCIAL_NETWORKS;

  return (
    <Box style={{ ...additionalStyles }} className={classes.footer}>
      <img src={OiroLogo} width={80} style={{ marginRight: 20 }} />
      <IconButton
        className={classes.footerSocialIcons}
        aria-label="enviar email"
      >
        <Mail />
      </IconButton>

      <a href={facebook} target="__blank">
        <IconButton className={classes.footerSocialIcons} aria-label="facebook">
          <FaFacebookF />
        </IconButton>
      </a>

      <a href={instagram} target="__blank">
        <IconButton
          className={classes.footerSocialIcons}
          aria-label="instagram"
        >
          <FaInstagram />
        </IconButton>
      </a>

      <RouterLink to="#" className={classes.footerLinks}>
        Download para Android
      </RouterLink>
      <RouterLink to="#" className={classes.footerLinks}>
        Download para IOS
      </RouterLink>
      <RouterLink to="/privacy-and-policy" className={classes.footerLinks}>
        Termos de Serviço
      </RouterLink>
    </Box>
  );
};

export default Footer;
